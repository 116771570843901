import React, {memo} from 'react'
import {Flex, Typography} from 'antd'

const {Title, Text} = Typography

export type AppGeneralInformationItemProps = {
    title: string,
    value: string
}

const AppGeneralInformationItem: React.FC<AppGeneralInformationItemProps> = ({
  title,
  value,
}) => (
  <Flex vertical>
    <Title level={5}>{title}</Title>
    <Text>{value}</Text>
  </Flex>
)

export default memo(AppGeneralInformationItem)
