import {
  objectDecoder,
  stringDecoder,
  arrayDecoder,
  oneOfDecoders,
  undefinedDecoder,
  boolDecoder,
} from 'json-decoder'
import {platformDecoder} from './Platform'
import {MobileAppLinks, mobileAppLinksDecoder} from './MobileAppLinks'
import {Decoders} from '../helpers/Decoders'
import {DistributionMethod, distributionMethodDecoder} from './DistributionMethod'
import {divisionDecoder} from './Division'
import {Publisher, publisherDecoder} from './Publisher'
import {Assessments, assessmentsDecoder} from './Assessments'
import {MobileAppDemo, mobileAppDemoDecoder} from './MobileAppDemo'
import {MobileAppStatus, mobileAppStatusDecoder} from './MobileAppStatus'
import {mobileAppStatusUIDecoder} from './MobileAppStatusUI'
import {MobileAppListItem} from './MobileAppListItem'
import {jiraIssueDecoder} from './JiraIssue'

export type MobileAppDetail = MobileAppListItem & {
  appIdentifier: string
  assessments: Assessments
  cmdbId: string
  contactEmail: string
  demo: MobileAppDemo
  description: string
  distributionMethod: DistributionMethod
  isRip: boolean
  lastReleaseDate?: Date
  links: MobileAppLinks
  publisher: Publisher
  screenshots: string[]
  status: MobileAppStatus
  testerEmails: string[]
}

// TO-DO: Update this decoder to match backend
export const mobileAppDetailDecoder = objectDecoder<MobileAppDetail>({
  id: stringDecoder,
  appIdentifier: stringDecoder,
  assessments: assessmentsDecoder,
  cmdbId: stringDecoder,
  contactEmail: stringDecoder,
  demo: mobileAppDemoDecoder,
  description: stringDecoder,
  distributionMethod: distributionMethodDecoder,
  division: divisionDecoder,
  icon: oneOfDecoders(stringDecoder, undefinedDecoder),
  isRip: boolDecoder,
  jiraIssue: jiraIssueDecoder,
  lastReleaseDate: oneOfDecoders(Decoders.dateDecoder, undefinedDecoder),
  links: mobileAppLinksDecoder,
  name: stringDecoder,
  platform: platformDecoder,
  publisher: publisherDecoder,
  screenshots: arrayDecoder(stringDecoder),
  status: mobileAppStatusDecoder,
  statusUI: oneOfDecoders(mobileAppStatusUIDecoder, undefinedDecoder),
  testerEmails: arrayDecoder(stringDecoder),
  version: stringDecoder,
})
