import {strings} from '../localization/strings'

export enum Section {
  Android,
  Ios,
  Rip
}

export const getSectionTitle = (section: Section): string => {
  switch (section) {
    case Section.Android:
      return strings.appsSection.androidApplications
    case Section.Ios:
      return strings.appsSection.iOSApplications
    case Section.Rip:
      return strings.appsSection.releasesInProgress
    default:
      throw new Error(`Not supported section! Allowed values are: ${Object.keys(Section)}`)
  }
}

export const getEmptySectionMessage = (section: Section, searchQuery?: string): string => {
  switch (section) {
    case Section.Android:
      return searchQuery
        ? `${strings.search.androidAppNotFound} "${searchQuery}"`
        : strings.app.androidAppsNotFound

    case Section.Ios:
      return searchQuery
        ? `${strings.search.iosAppNotFound} "${searchQuery}"`
        : strings.app.iOsAppsNotFound
    // Empty section can be only in case of searching - section is hidden when there are no RIPs
    case Section.Rip:
      return `${strings.search.releaseInProgressNotFound} "${searchQuery}"`
    default:
      throw new Error(`Not supported section! Allowed values are: ${Object.keys(Section)}`)
  }
}
