import React, {useMemo} from 'react'
import {useParams} from 'react-router'
import {Platform} from '../types/Platform'
import {useAppDetail} from '../hooks/network/useAppDetail'
import {Spinner} from '../components/Spinner'
import {strings} from '../localization/strings'
import {Alert, Button, Flex, Layout, Typography} from 'antd'
import {styles} from './AppDetail.styles'
import {AppDetailHeader} from '../components/AppDetail/AppDetailHeader'
import AppGeneralInformationSection from '../components/AppDetail/AppGeneralInformationSection'
import AppLinksSection from '../components/AppDetail/AppLinksSection'
import {AppLinksHelper} from '../helpers/AppLinksHelper'
import AppScreenshotsSection from '../components/AppDetail/AppScreenshotsSection'
import {Content} from 'antd/es/layout/layout'
import Paragraph from 'antd/es/typography/Paragraph'
import {useMaspNavigate} from '../hooks/useMaspNavigate'
import AppRipSection from '../components/AppDetail/AppRipSection'
import {AppStatusChangeCallbackParams} from '../components/AppStatus/StatusSettings'
import {MobileAppStatusName} from '../types/MobileAppStatusName'
import {MobileAppStatusUI} from '../types/MobileAppStatusUI'

type AppDetailParams = {
  id?: string
  platform?: Platform
}

const AppDetail: React.FC = () => {
  const {id, platform} = useParams<AppDetailParams>()
  const {appDetail, updateAppStatus, isLoading, error} = useAppDetail(id, platform)
  const {navigateSubmission} = useMaspNavigate()

  const appLinks = useMemo(() => {
    if (!appDetail) {
      return undefined
    }
    return AppLinksHelper.getAppLinks(appDetail.links, appDetail.platform)
  }, [appDetail])

  const handleNewReleaseClick = () => {
    navigateSubmission(id, platform)
  }

  const handleAppStatusChange: AppStatusChangeCallbackParams =
  (_id: string,
    status: MobileAppStatusName,
    statusUI?: MobileAppStatusUI,
    isRip?: boolean,
    releaseDate?: Date,
  ) => {
    updateAppStatus(status, statusUI, isRip, releaseDate)
  }

  if (isLoading) {
    return <Spinner tip={strings.loadingMessage.fetchingData} />
  }

  if (error) {
    return (
      <Alert
        message={strings.appDetail.notFound}
        description={error.message}
        showIcon
        type="error"
      />
    )
  }

  return appDetail && (
    <Layout>
      <Content style={styles.container}>
        <Flex vertical>
          <Flex>
            <AppDetailHeader
              icon={appDetail.icon}
              name={appDetail.name}
              platform={appDetail.platform}
              version={appDetail.version}
              status={appDetail.status.name} />
            {
              appDetail?.status.name === MobileAppStatusName.Released &&
              <Button
                type="primary"
                size="large"
                style={styles.releaseButton}
                onClick={handleNewReleaseClick}>
                {strings.buttonTitle.newRelease}
              </Button>
            }
          </Flex>

          {appDetail.isRip &&
          <AppRipSection mobileApp={appDetail} onAppStatusChange={handleAppStatusChange} />
          }

          <AppGeneralInformationSection mobileApp={appDetail} />

          <Typography.Title level={4}>{strings.appDetail.appDescriptionTitle}</Typography.Title>

          <Paragraph
            style={styles.description}
            ellipsis={{rows: 10, expandable: true, symbol: 'more'}}>
            {appDetail.description}
          </Paragraph>

          {appLinks && appLinks.length > 0 && <AppLinksSection appLinks={appLinks} />}
          {
            appDetail.screenshots.length > 0 &&
            <AppScreenshotsSection screenshots={appDetail.screenshots} />
          }
        </Flex>
      </Content>
    </Layout>
  )
}

export default AppDetail
