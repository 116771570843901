import {Styles} from '../../types/Styles'

export const styles: Styles = {
  card: {
    textAlign: 'left',
    borderRadius: 16,
    border: '1px solid lightgrey',
    marginRight: 8,
    marginBottom: 8,
  },
  appIcon: {
    borderRadius: '18%',
  },
  releaseButton: {
    borderRadius: 8,
  },
  appInfo: {
    height: 80,
    width: 200,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  appName: {
    margin: '0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    color: 'black',
    fontSize: '15px',
  },
  appDetail: {
    margin: '0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    color: 'black',
    fontSize: '13px',
  },
  platformIcon: {
    verticalAlign: 'baseline',
    margin: '0',
  },
  jiraTag: {
    borderRadius: 6,
    marginRight: 4,
  },
}
