import {useCallback, useEffect, useRef, useState} from 'react'
import {NetworkService} from '../../services/NetworkService'
import {config} from '../../config/config'
import {Platform} from '../../types/Platform'
import {MaspError, MaspErrorType} from '../../errors/MaspError'
import {MobileAppDetail} from '../../types/MobileAppDetail'
import {MobileAppStatusName} from '../../types/MobileAppStatusName'
import {MobileAppStatusUI} from '../../types/MobileAppStatusUI'

export type AppDetailResult = {
  appDetail?: MobileAppDetail
  updateAppStatus: (
    status: MobileAppStatusName,
    statusUI?: MobileAppStatusUI,
    isRip?: boolean,
    releaseDate?: Date
  ) => void
  isLoading: boolean
  error?: MaspError
}

export const useAppDetail = (id?: string, platform?: Platform): AppDetailResult => {
  const [appDetail, setAppDetail] = useState<MobileAppDetail | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<MaspError | undefined>(undefined)

  const {current: networkService} = useRef(new NetworkService(config.backendBaseUrl, config.msal))

  const fetchAppDetail = useCallback(async () => {
    if (!id || !platform) {
      const appDetailMissingParamsError = new MaspError(MaspErrorType.AppDetailMissingParams)
      // eslint-disable-next-line no-console
      console.error(`🚨 ${appDetailMissingParamsError.message}`)
      setError(appDetailMissingParamsError)
      return
    }

    setIsLoading(true)

    try {
      const mobileAppDetail = await networkService.getMobileAppDetail(platform, id)
      setAppDetail(mobileAppDetail)
    } catch (networkError: any) {
      setError(networkError)
    } finally {
      setIsLoading(false)
    }
  }, [id, platform, networkService])

  const updateAppStatus = useCallback((
    status: MobileAppStatusName,
    statusUI?: MobileAppStatusUI,
    isRip?: boolean,
    releaseDate?: Date,
  ) => {
    setAppDetail((prevAppDetail) => {
      if (!prevAppDetail) {
        return prevAppDetail
      }
      return {
        ...prevAppDetail,
        status: {name: status},
        statusUI,
        isRip: (isRip !== undefined) ? isRip : prevAppDetail.isRip,
        lastReleaseDate: releaseDate || prevAppDetail.lastReleaseDate,
      }
    })
  }, [])

  useEffect(() => {
    fetchAppDetail()
  }, [fetchAppDetail])

  return {
    appDetail,
    updateAppStatus,
    isLoading,
    error,
  }
}
