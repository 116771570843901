import React from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import {MDSConfigProvider} from '@mds/merck-design-system'
import {Pages} from './components/Navigation'
import Page from './components/Page'

import Home from './routes/Home'
import AppSubmission from './routes/AppSubmission'
import AppDetail from './routes/AppDetail'

const basename = document.querySelector('base')?.getAttribute('href') ?? '/'

const AppRouter: React.FC = () => (
  <BrowserRouter basename={basename}>
    <MDSConfigProvider theme="merckLightTheme">
      <Page>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path={Pages.detail} element={<AppDetail />} />
          <Route path={Pages.submission} element={<AppSubmission />} />
        </Routes>
      </Page>
    </MDSConfigProvider>
  </BrowserRouter>
)

export default AppRouter
