import React, {PropsWithChildren} from 'react'
import './DividerSegment.css'
import {Divider, Space} from 'antd'

interface DividerSegmentProps extends PropsWithChildren {
  title: string
  icon: React.ReactNode
  children: React.ReactNode
}

const DividerSegment: React.FC<DividerSegmentProps> = ({title, icon, children}) => (
  <div className="container">
    <Divider orientation="center">
      <Space align="center">
        {icon}
        <span className="bold" style={{fontSize: '1.25rem'}}>{title}</span>
      </Space>
    </Divider>
    <>{children}</>
  </div>
)

export default DividerSegment
