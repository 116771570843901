import React from 'react'
import {Form, Button} from 'antd'
import AppInformationSection from './AppInformationSection'
import {strings} from '../../localization/strings'
import ComplianceSection from './ComplianceSection'
import TestingSection from './TestingSection'
import {styles} from './AppSubmissionForm.styles'
import PackagingSection from './PackagingSection'
import {MobileAppDetail} from '../../types/MobileAppDetail'
import AppPublishingInformationSection from './AppPublishingInformationSection'
import {AppPublishingInformationHelper} from '../../helpers/AppPublishingInformationHelper'

export type AppSubmissionFormProps = {
  isAppUpdate: boolean
  initialFormData: Partial<MobileAppDetail>
  previousAppVersion: string
  onSubmit: (data: Partial<MobileAppDetail>) => void
}

const AppSubmissionForm: React.FC<AppSubmissionFormProps> = ({
  isAppUpdate,
  initialFormData,
  previousAppVersion,
  onSubmit,
}) => {
  const [form] = Form.useForm<Partial<MobileAppDetail>>()

  return (
    <div style={styles.formContainer}>
      <AppPublishingInformationSection
        appPublishingInformations={
          AppPublishingInformationHelper.appPublishingInformations
        }
      />
      <Form
        form={form}
        labelCol={{span: 7}}
        labelWrap
        wrapperCol={{span: 40}}
        initialValues={initialFormData}
        onFinish={onSubmit}
        layout="horizontal"
      >
        <div style={styles.formSection}>
          <AppInformationSection
            isAppUpdate={isAppUpdate}
            previousAppVersion={previousAppVersion}
          />
          <ComplianceSection isAppUpdate={isAppUpdate} />
          <TestingSection />
          <PackagingSection />
        </div>

        <div style={styles.submitButtonContainer}>
          <Button type="primary" htmlType="submit">
            {strings.buttonTitle.submit}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default AppSubmissionForm
