import React, {useMemo} from 'react'
import {Flex, Image, Space, Tag, Typography} from 'antd'
import {Platform} from '../../types/Platform'
import {
  AndroidFilled,
  AppleFilled,
} from '@ant-design/icons'
import {styles} from './AppDetailHeader.styles'
import {MobileAppStatusName} from '../../types/MobileAppStatusName'
import {AppStatusTag} from '../AppStatus/AppStatusTag'

const {Title} = Typography
const DEFAULT_ICON = '/images/merck_teal_default_icon.png'

export type AppDetailHeaderProps = {
  icon?: string
  name: string
  platform: Platform
  version: string
  status: MobileAppStatusName
}

export const AppDetailHeader: React.FC<AppDetailHeaderProps> = ({
  icon, name, platform, version, status,
}) => {
  const platformIcon = useMemo(() => (
    platform === Platform.Ios
      ? <AppleFilled style={styles.tagIcon} />
      : <AndroidFilled style={styles.tagIcon} />
  ), [platform])

  return (
    <Flex vertical>
      <Flex>
        <Image
          src={icon}
          preview={false}
          fallback={DEFAULT_ICON}
          style={styles.icon} />
        <Flex vertical>
          <Title level={1} style={styles.title}>
            {name}
          </Title>
          <Space size={0}>
            <AppStatusTag status={status} />
            <Tag style={styles.tag}>v {version}</Tag>
            <Tag style={styles.tag}>
              {platformIcon}
              {platform}
            </Tag>
          </Space>
        </Flex>
      </Flex>
    </Flex>
  )
}

AppDetailHeader.defaultProps = {
  icon: undefined,
}
