import React, {useEffect} from 'react'
import {Form, Input, Radio} from 'antd'
import {DistributionMethod, getAppIdentifierPrefix} from '../../types/DistributionMethod'
import {strings} from '../../localization/strings'
import {regexPattern} from '../../types/RegexPattern'
import {Platform} from '../../types/Platform'
import AppSubmissionFormHeader from './AppSubmissionFormHeader'
import {fieldName} from './AppSubmissionFieldNames'
import {useWatch} from 'antd/es/form/Form'
import {InfoCircleOutlined} from '@ant-design/icons'
import {styles} from './AppSubmissionForm.styles'
import {compare, validate} from 'compare-versions'

export type AppInformationSectionProps = {
  isAppUpdate: boolean
  previousAppVersion: string
}

const AppInformationSection: React.FC<AppInformationSectionProps> = ({
  isAppUpdate,
  previousAppVersion,
}) => {
  const form = Form.useFormInstance()

  const platform = useWatch<Platform>(fieldName.platform, form)
  const appAvailability = useWatch<DistributionMethod>(fieldName.appAvailability, form)
  const appIdentifierPrefix = getAppIdentifierPrefix(appAvailability)

  const {submission: texts} = strings
  const {validation: validationMessage, placeholder, label} = texts

  useEffect(() => {
    const currentAppVersion = form.getFieldValue(fieldName.version)
    if (previousAppVersion && compare(currentAppVersion, previousAppVersion, '<=')) {
      form.setFieldValue(fieldName.version, '')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const versionValidator = (_rule: any, value: any) => {
    if (!value) {
      return Promise.resolve()
    }
    if (!validate(value)) {
      return Promise.reject(validationMessage.invalidAppVersion)
    } else if (previousAppVersion && compare(value, previousAppVersion, '<=')) {
      return Promise.reject(validationMessage.versionNotHigher)
    }
    return Promise.resolve()
  }

  const appIdentifierValidator = (_rule: any, value: any) => {
    if (!value) {
      return Promise.resolve()
    }
    if (platform === Platform.Ios && !regexPattern.iOSBundleId.test(value)) {
      return Promise.reject(validationMessage.invalidIosAppId)
    } else if (platform === Platform.Android && !regexPattern.androidAppId.test(value)) {
      return Promise.reject(validationMessage.invalidAndroidAppId)
    }
    return Promise.resolve()
  }

  return (
    <>
      <AppSubmissionFormHeader title={texts.appInformation} />

      <Form.Item
        label={label.name}
        name={fieldName.name}
        rules={[{required: true, message: validationMessage.appNameRequired}]}
      >
        <Input
          count={{
            show: true,
            max: 30,
          }}
          maxLength={30} />
      </Form.Item>
      <Form.Item
        label={label.version}
        name={fieldName.version}
        rules={[
          {
            required: true, message: validationMessage.appVersionRequired,
          },
          {
            validator: versionValidator,
          },
        ]}
        extra={previousAppVersion && (
          <span style={styles.infoExtra}>
            <InfoCircleOutlined style={styles.iconPrefix} />
            {texts.previousVersionInfo(previousAppVersion)}
          </span>
        )}
      >
        <Input
          placeholder={placeholder.appVersion}
        />
      </Form.Item>
      <Form.Item
        label={label.appAvailability}
        name={fieldName.appAvailability}
        rules={[{required: true, message: validationMessage.appAvailabilityRequired}]}
      >
        <Radio.Group disabled={isAppUpdate}>
          <Radio.Button value={DistributionMethod.ABM}>{texts.internal}</Radio.Button>
          <Radio.Button value={DistributionMethod.Public}>{texts.public}</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={label.platform}
        name={fieldName.platform}
        rules={[{required: true, message: validationMessage.platformRequired}]}
      >
        <Radio.Group disabled={isAppUpdate}>
          <Radio.Button value={Platform.Ios}>{Platform.Ios}</Radio.Button>
          <Radio.Button value={Platform.Android}>{Platform.Android}</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={label.appIdentifier}
        name={fieldName.appIdentifier}
        rules={[
          {required: true, message: validationMessage.appIdRequired},
          {validator: appIdentifierValidator},
        ]}
      >
        <Input
          addonBefore={isAppUpdate ? '' : appIdentifierPrefix}
          disabled={isAppUpdate}
        />
      </Form.Item>
      <Form.Item
        label={label.contactEmail}
        name={fieldName.contactEmail}
        rules={[
          {
            required: true,
            message: validationMessage.emailRequired,
          },
          {
            pattern: regexPattern.companyEmail,
            message: validationMessage.invalidCompanyEmail,
          },
        ]}
      >
        <Input type="email" />
      </Form.Item>
      <Form.Item
        label={label.description}
        name={fieldName.description}
        rules={[{required: true, message: validationMessage.appDescRequired}]}
      >
        <Input.TextArea
          count={{
            show: true,
            max: 4000,
          }}
          rows={4}
          maxLength={4000} />
      </Form.Item>
    </>
  )
}

export default AppInformationSection
