import React from 'react'
import {MobileAppStatusName} from '../../types/MobileAppStatusName'
import {Tag} from 'antd'
import {styles} from '../AppDetail/AppDetailHeader.styles'
import {
  ClockCircleFilled,
  StopFilled,
  CheckCircleFilled,
  ExclamationCircleFilled,
  MinusCircleFilled,
} from '@ant-design/icons'
import {strings} from '../../localization/strings'

export type AppStatusTagProps = { status: MobileAppStatusName }

export const AppStatusTag: React.FC<AppStatusTagProps> = ({status}) => {
  const getStatusTagColor = () => {
    switch (status) {
      case MobileAppStatusName.Released:
        return 'green'
      case MobileAppStatusName.Cancelled:
      case MobileAppStatusName.Retired:
        return 'error'
      case MobileAppStatusName.Archived:
        return 'warning'
      default:
        return 'volcano'
    }
  }

  const getStatusTagIcon = () => {
    switch (status) {
      case MobileAppStatusName.Released:
        return <CheckCircleFilled style={styles.tagIcon} />
      case MobileAppStatusName.Cancelled:
        return <StopFilled style={styles.tagIcon} />
      case MobileAppStatusName.Retired:
        return <MinusCircleFilled style={styles.tagIcon} />
      case MobileAppStatusName.Archived:
        return <ExclamationCircleFilled style={styles.tagIcon} />
      default:
        return <ClockCircleFilled style={styles.tagIcon} />
    }
  }

  const getStatusTagString = () => {
    switch (status) {
      case MobileAppStatusName.Released:
        return strings.appDetail.released
      case MobileAppStatusName.Cancelled:
        return strings.appDetail.cancelled
      case MobileAppStatusName.Retired:
        return strings.appDetail.retired
      case MobileAppStatusName.Archived:
        return strings.appDetail.archived
      default:
        return strings.appDetail.releaseInProgress
    }
  }

  return (
    <Tag color={getStatusTagColor()} style={styles.tag}>
      {getStatusTagIcon()}
      {getStatusTagString()}
    </Tag>
  )
}
