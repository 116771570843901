import React, {useCallback} from 'react'
import DividerSegment from '../DividerSegment/DividerSegment'
import {AndroidFilled, AppleFilled, ClockCircleFilled} from '@ant-design/icons'
import {Space, Alert} from 'antd'
import AppCard from '../AppCard/AppCard'
import {Platform} from '../../types/Platform'
import {MobileAppListItem} from '../../types/MobileAppListItem'
import {Section, getEmptySectionMessage, getSectionTitle} from '../../types/Section'
import {AppStatusChangeCallbackParams} from '../AppStatus/StatusSettings'
import {styles} from './AppsSection.styles'
import {useMaspNavigate} from '../../hooks/useMaspNavigate'

export type AppsSectionProps = {
  section: Section
  apps: MobileAppListItem[]
  searchQuery: string
  // eslint-disable-next-line react/require-default-props
  onAppStatusChange?: AppStatusChangeCallbackParams
}

const AppsSection: React.FC<AppsSectionProps> = ({
  section,
  apps,
  searchQuery,
  onAppStatusChange,
}) => {
  const {navigateSubmission, navigateDetail} = useMaspNavigate()

  const handleUpdateClick = (id: string, platform: Platform) : void => {
    navigateSubmission(id, platform)
  }

  const handleAppCardClick = (
    id: string,
    platform: Platform,
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) : void => {
    const eventTargetName = (event.target as Element).className
    // 'Antd Datepicker' and 'Antd Modal' doesn't provide event for the datepicker change and modal view clicking. In order to stop the event propagation, blocking the navigation to detail using 'eventTargetName' which starts with 'ant-picker' and 'ant-modal'.
    if ((eventTargetName.startsWith('ant-picker')) || (eventTargetName.startsWith('ant-modal'))) {
      return
    }
    navigateDetail(id, platform)
  }

  const getSectionIcon = useCallback(() => {
    switch (section) {
      case Section.Android:
        return <AndroidFilled style={styles.icon} />
      case Section.Ios:
        return <AppleFilled style={styles.icon} />
      case Section.Rip:
        return <ClockCircleFilled style={styles.icon} />
      default:
        return <></>
    }
  }, [section])

  return (
    <DividerSegment title={getSectionTitle(section)} icon={getSectionIcon()}>
      {apps.length
        ? (
          <Space wrap style={{marginBottom: -20}}>
            {
              apps.map((app) => (
                <AppCard
                  key={app.id}
                  mobileApp={app}
                  isRip={section === Section.Rip}
                  onAppCardClick={handleAppCardClick}
                  onUpdateClick={handleUpdateClick}
                  onAppStatusChange={onAppStatusChange} />
              ))
            }
          </Space>
        )
        : <Alert message={getEmptySectionMessage(section, searchQuery)} type="warning" />}
    </DividerSegment>
  )
}

export default AppsSection
