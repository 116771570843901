export type RegexPattern = {
    companyEmail: RegExp,
    email: RegExp,
    iOSBundleId: RegExp,
    androidAppId: RegExp,
}

export const regexPattern: RegexPattern = {
  companyEmail: /^\w+([.-]?\w+)*@(merck\.com|msd\.com)$/,
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  iOSBundleId: /^[a-zA-Z0-9.-]+$/,
  androidAppId: /^[a-zA-Z][a-zA-Z0-9._]*$/,
}
