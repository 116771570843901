import type {ResultStatusType} from 'antd/es/result'
import {MaspError} from '../errors/MaspError'
import {SubmissionResponse} from './SubmissionResponse'

export type SubmissionResult = {
    status: ResultStatusType,
    response?: SubmissionResponse,
    error?: MaspError
    errorType?: SubmissionErrorType
}

export enum SubmissionErrorType {
    FetchAppDetail,
    FormSubmission
}
