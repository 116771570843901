import React from 'react'
import {Alert} from 'antd'
import './EnvironmentBanner.css'
import {config} from '../config/config'

const alertMessage = `This is ${config.environment} environment. Not a released version`

const EnvironmentBanner: React.FC = () => (
  <Alert
    className="envBanner"
    type="error"
    showIcon={false}
    message={alertMessage}
    banner
  />
)

export default EnvironmentBanner
