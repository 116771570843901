import React from 'react'
import {Menu} from 'antd'

export enum Pages {
  home = '/',
  detail = '/:platform/:id',
  submission = '/submission',
}

// TODO: remove the 'Navigation' component (https://issues.merck.com/browse/MASP-282)
const Navigation: React.FC = () => (
  <Menu
    defaultSelectedKeys={['/']}
    mode="horizontal"
  />
)

export default Navigation
