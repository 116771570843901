import {useCallback, useRef, useState} from 'react'
import {NetworkService} from '../../services/NetworkService'
import {config} from '../../config/config'

type MaspDataDownloaderResult = {
    isLoading: boolean
    fetchPortfolio: () => void
    fetchStatusChanges: () => void
    error: any
}

export const useMaspDataDownloader = (): MaspDataDownloaderResult => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState(null)
  const {current: networkService} = useRef(new NetworkService(config.backendBaseUrl, config.msal))

  const downloadCsvData = useCallback((csvData: string, filename: string) => {
    const blob = new Blob([csvData], {type: 'text/csv'})
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${filename}.csv`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }, [])

  const fetchPortfolio = useCallback(() => {
    setIsLoading(true)
    const responseCsv = networkService.fetchMobileAppCsv()
    responseCsv
      .then((data) => {
        downloadCsvData(data, 'MASP-data')
      })
      .catch((e) => {
        setError(e)
        // eslint-disable-next-line no-console
        console.error(`🚨 ${e.message}`)
      })
      .finally(() => {
        setIsLoading(false)
        setTimeout(() => {
          setError(null)
        }, 2000)
      })
  }, [downloadCsvData, networkService])

  const fetchStatusChanges = useCallback(() => {
    setIsLoading(true)
    const responseCsv = networkService.fetchStatusChangesCsv()
    responseCsv
      .then((data) => {
        downloadCsvData(data, 'MASP-StatusChanges')
      })
      .catch((e) => {
        setError(e)
        // eslint-disable-next-line no-console
        console.error(`🚨 ${e.message}`)
      })
      .finally(() => {
        setIsLoading(false)
        setTimeout(() => {
          setError(null)
        }, 2000)
      })
  }, [downloadCsvData, networkService])

  return {isLoading, fetchPortfolio, fetchStatusChanges, error}
}
