import React from 'react'
import {Header, Footer} from '@mds/merck-design-system'
import Navigation, {Pages} from './Navigation'

import './Page.css'
import EnvironmentBanner from '../components/EnvironmentBanner'
import {config} from '../config/config'
import {Environment} from '../types/Environment'
import LoggedInUser from './LoggedInUser'
import {FloatButton, Typography} from 'antd'
import {StringHelper} from '../helpers/StringHelper'
import {Link} from 'react-router-dom'
import {strings} from '../localization/strings'
import {MailOutlined} from '@ant-design/icons'

const {Text} = Typography

type Props = {
  children: React.ReactNode
}

const isNonProdEnv = config.environment !== Environment.Prod

const Page: React.FC<Props> = ({children}) => {
  const {webAppTitle, appVersion, buildNumber} = config

  return (
    <div className="page">
      <FloatButton
        shape="circle"
        type="primary"
        icon={<MailOutlined />}
        href={`mailto:${config.contactEmail}`}
        className="contactUsButton"
      />
      <Header
        title={webAppTitle}
        Logo={() => (<img
          src={`${process.env.PUBLIC_URL}/images/company_logo_white.png`}
          alt="Company logo"
        />)}
      >
        <Navigation />
        <Link to={Pages.submission} id="submissionLink">
          {strings.buttonTitle.initialRelease}
        </Link>
        <LoggedInUser />
      </Header>
      <div className="page__content">
        {isNonProdEnv && <EnvironmentBanner />}
        {children}
      </div>
      <Footer>
        <Text className="footer-app-version">
          {StringHelper.getAppVersionWithBuildNumber(appVersion, buildNumber)}
        </Text>
      </Footer>
    </div>
  )
}

export default Page
