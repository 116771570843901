import React, {PureComponent} from 'react'
import {withMsal} from '@azure/msal-react'
import {UserOutlined, LogoutOutlined} from '@ant-design/icons'
import {config} from '../config/config'
import {Space, message} from 'antd'
import {MaspError, MaspErrorType} from '../errors/MaspError'
import './LoggedInUser.css'

// TODO: Use useMsal and useUserInfo hooks
export class LoggedInUser extends PureComponent<any> {
  private handleLogout = async() => {
    if (config.msal.isActive) {
      try {
        await this.props.msalContext.instance.logoutRedirect()
      } catch (error: any) {
        const msapError = new MaspError(MaspErrorType.MsalAuthentication, error.message)
        message.error(msapError.message)
      }
    } else {
      message.info('Mocked user cannot logout.')
    }
  }

  render = () => {
    const {accounts} = this.props.msalContext

    return (
      <div className="logged-in-user">
        <Space align="center">
          <UserOutlined className="logged-in-user-avatar" />
          {config.msal.isActive && this.props.msalContext.accounts.length > 0
            ? accounts[0].name
            : 'Mocked user'}
          <LogoutOutlined
            className="logged-in-user-logout"
            onClick={this.handleLogout}
          />
        </Space>
      </div>
    )
  }
}

export default withMsal(LoggedInUser)
