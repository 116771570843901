import {Styles} from '../../types/Styles'

export const styles: Styles = {
  icon: {
    display: 'block',
    width: 100,
    height: 100,
    borderRadius: '25%',
    marginRight: 15,
  },
  title: {
    marginBottom: 4,
    marginTop: 5,
  },
  tagIcon: {
    marginRight: 3,
  },
  tag: {
    borderRadius: 6,
  },
}
