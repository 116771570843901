import {objectDecoder, stringDecoder} from 'json-decoder'

export type ArtifactoryCredentials = {
  username: string
  password: string
  apiKey: string
}

export const artifactoryCredentialsDecoder = objectDecoder<ArtifactoryCredentials>({
  username: stringDecoder,
  password: stringDecoder,
  apiKey: stringDecoder,
})
