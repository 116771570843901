import React, {memo} from 'react'
import {Button, Dropdown, Space, message} from 'antd'
import {DownOutlined} from '@ant-design/icons'
import {strings} from '../../localization/strings'
import {styles} from './MaspDataDownloader.styles'
import type {MenuProps} from 'antd'
import {useMaspDataDownloader} from './MaspDataDownloader.hooks'

const MaspDataDownloader: React.FC = () => {
  const {isLoading, fetchPortfolio, fetchStatusChanges, error} = useMaspDataDownloader()
  const {buttonTitle} = strings

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === buttonTitle.portfolio) {
      fetchPortfolio()
    } else {
      fetchStatusChanges()
    }
  }

  const items: MenuProps['items'] = [
    {
      label: buttonTitle.portfolio,
      key: buttonTitle.portfolio,
    },
    {
      label: buttonTitle.statusChanges,
      key: buttonTitle.statusChanges,
    },
  ]

  const menuProps = {
    items,
    onClick: handleMenuClick,
  }

  if (error) {
    message.error(error.message)
  }

  return (
    <Dropdown menu={menuProps}>
      <Button
        type="primary"
        size="large"
        loading={isLoading}
        style={styles.downloadButton}
      >
        <Space>
          {buttonTitle.exportData}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  )
}

export default memo(MaspDataDownloader)
