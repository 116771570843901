import React, {useEffect, useState} from 'react'
import {Result, Button} from 'antd'
import {SubmissionResult, SubmissionErrorType} from '../../types/SubmissionResult'
import {config} from '../../config/config'
import {strings} from '../../localization/strings'
import {useMaspNavigate} from '../../hooks/useMaspNavigate'

export type AppSubmissionResultProps = {
    result: SubmissionResult,
    onRetry: () => void
}

const AppSubmissionResult: React.FC<AppSubmissionResultProps> = ({
  result,
  onRetry,
}) => {
  const {navigateHome} = useMaspNavigate()
  const [title, setTitle] = useState<string>('')
  const [subtitle, setSubtitle] = useState<string>('')

  const {result: texts} = strings.submission

  useEffect(() => {
    if (result.status === 'success' && result.response) {
      setTitle(texts.successTitle)
      setSubtitle(result.response.message)
    } else if (result.status === 'error' && result.error) {
      setTitle(texts.errorTitle)
      setSubtitle(
        result.errorType === SubmissionErrorType.FetchAppDetail
          ? texts.fetchDetailErrorMessage
          : texts.submissionErrorMessage,
      )
    }
  }, [result, texts])

  const getExtraButtons = () => {
    if (result.status === 'success') {
      return (
        <>
          <Button
            type="primary"
            onClick={() => navigateHome()}
          >
            {texts.goHome}
          </Button>
          {
            result.response?.jiraTicketLink &&
            <Button
              href={result.response.jiraTicketLink}
              target="_blank"
            >
              {texts.viewJiraTicket}
            </Button>
          }
        </>
      )
    } else if (result.status === 'error') {
      return (
        <>
          <Button
            type="primary"
            onClick={onRetry}
          >
            {texts.retry}
          </Button>
          <Button href={`mailto:${config.contactEmail}`}>
            {texts.contactUs}
          </Button>
        </>
      )
    }
    return null
  }

  return (
    <Result
      status={result.status}
      title={title}
      subTitle={subtitle}
      extra={getExtraButtons()}
    />
  )
}

export default AppSubmissionResult
