import React from 'react'
import {Spin} from 'antd'

import './Spinner.css'
import {strings} from '../localization/strings'

type SpinnerProps = {
  tip?: string
  isSpinning?: boolean
  children?: React.JSX.Element
}

export const Spinner: React.FC<SpinnerProps> = ({tip, isSpinning, children}) => (
  <Spin
    className="spinner"
    size="large"
    tip={tip}
    spinning={isSpinning}
  >
    { children || <div className="spinner-children" /> }
  </Spin>
)

Spinner.defaultProps = {
  tip: strings.app.loading,
  isSpinning: true,
  children: undefined,
}
