import React, {memo} from 'react'
import {Typography} from 'antd'
import {strings} from '../../localization/strings'
import {AppPublishingInformation} from '../../types/AppPublishingInformation'

const {Title, Text, Link, Paragraph} = Typography

export type AppPublishingInformationSectionProps = {
  appPublishingInformations: AppPublishingInformation[]
}

const AppPublishingInformationSection: React.FC<AppPublishingInformationSectionProps> = ({
  appPublishingInformations,
}) => (
  <Typography>
    <Title level={5}>
      {strings.submission.formDescriptionTitle}
    </Title>
    <Paragraph>
      <ul>
        {
          appPublishingInformations.map((appPublishingInformation) => (
            <li key={appPublishingInformation.title}>
              <Text italic>
                {appPublishingInformation.title}
              </Text>
              {
                appPublishingInformation.link &&
                appPublishingInformation.linkText &&
                <Link href={appPublishingInformation.link}>
                  {appPublishingInformation.linkText}
                </Link>
              }
            </li>
          ))
        }
      </ul>
    </Paragraph>
  </Typography>
)

export default memo(AppPublishingInformationSection)
