import './FailureCard.css'
import {Alert} from 'antd'
import {strings} from '../../localization/strings'
import {FunctionComponent} from 'react'

export type FailureCardProps = {
    title: string
    detail: string
    errorMessage?: string
    closable?: boolean
    onClose?: () => void
}

export const FailureCard: FunctionComponent<FailureCardProps> = ({
  title,
  detail,
  errorMessage,
  closable,
  onClose,
}) => {
  const errorDescription =
      detail +
      (errorMessage ? `${strings.error.detail} ${errorMessage}` : '')

  return (
    <Alert
      message={title}
      description={errorDescription}
      closable={closable}
      afterClose={onClose}
      type="error"
      showIcon
    />
  )
}

FailureCard.defaultProps = {
  errorMessage: undefined,
  closable: undefined,
  onClose: undefined,
}
