import {objectDecoder, oneOfDecoders, stringDecoder, undefinedDecoder} from 'json-decoder'

export type JiraIssue = {
  key?: string
  url?: string
}

export const jiraIssueDecoder = objectDecoder<JiraIssue>({
  key: oneOfDecoders(stringDecoder, undefinedDecoder),
  url: oneOfDecoders(stringDecoder, undefinedDecoder),
})
