import {useMemo} from 'react'
import {useMsal} from '@azure/msal-react'
import {StringHelper} from '../helpers/StringHelper'

// TODO: This is a temporary solution to check admin status. Optimized implementation will be done in the future.
const adminIsids = [
  'abdullrz',
  'dalberti',
  'gergelic',
  'hendenr',
  'malatam',
  'rajenaru',
  'teplyd',
]

type UserInfoReturnType = {
  fullName: string | undefined,
  isid: string | undefined,
  isAdmin: boolean,
  isAuthenticated: boolean
}

export const useUserInfo = (): UserInfoReturnType => {
  const {accounts} = useMsal()
  const [user] = accounts

  const isAuthenticated = useMemo<boolean>(() => !!user, [user])

  const fullName = useMemo<string | undefined>(() => user?.name, [user])

  const isid = useMemo<string | undefined>(() => {
    if (!user) {
      return undefined
    }
    return StringHelper.getIsidFromUsername(user.username)
  }, [user])

  const isAdmin = useMemo<boolean>(() => {
    if (!isid) {
      return false
    }
    return adminIsids.includes(isid)
  }, [isid])

  return {
    fullName,
    isid,
    isAdmin,
    isAuthenticated,
  }
}
