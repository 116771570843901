import {ConfigHelper} from '../helpers/ConfigHelper'
import {MaspConfig} from '../types/MaspConfig'
import {PublicClientApplication} from '@azure/msal-browser'

const getConfig = (
  configHelper: ConfigHelper,
  msalInstance: PublicClientApplication,
): MaspConfig => ({
  environment: configHelper.getEnvironment(),
  backendBaseUrl: configHelper.getBackendBaseUrl(),
  contactEmail: 'fp-mobile-platform@msd.com',
  webAppTitle: 'Mobile App Service Portal',
  msal: {
    instance: msalInstance,
    isActive: configHelper.isMsalActive(),
    scopes: configHelper.getMsalScopes(),
  },
  appVersion: configHelper.getAppVersion(process.env.REACT_APP_VERSION),
  buildNumber: process.env.REACT_APP_BUILD_NUMBER,
  artifactoryBaseUrl: configHelper.getArtifactoryBaseUrl(),
  releaseProcessOverviewUrl: 'https://go.msd.com/masp-release-process',
})

const configHelper = new ConfigHelper(process.env.REACT_APP_ENV)

const msalInstance = new PublicClientApplication({
  auth: {
    authority: 'https://login.microsoftonline.com/a00de4ec-48a8-43a6-be74-e31274e2060d',
    clientId: configHelper.getMsalClientId(),
    redirectUri: configHelper.getMsalRedirectUri(),
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false, // set this to 'true' if having issues on IE11 or Edge
  },
})

export const config = getConfig(configHelper, msalInstance)
