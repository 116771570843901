import React from 'react'
import {Modal, QRCode, Space, Typography} from 'antd'
import {
  CopyFilled,
} from '@ant-design/icons'
import {strings} from '../../localization/strings'
import {AppLink} from '../../types/AppLink'

export type AppInstallationLinkModalProps = {
    appLink: AppLink
    onCloseAppInstallationLinkModal: () => void
}
const {Paragraph, Text} = Typography

const AppInstallationLinkModal: React.FC<AppInstallationLinkModalProps> = ({
  appLink,
  onCloseAppInstallationLinkModal}) =>
  (
    <Modal
      title={appLink.title}
      open
      footer={null}
      onCancel={() => onCloseAppInstallationLinkModal()}
      width={600}>
      <Space direction="vertical">
        <Paragraph copyable={{icon: <CopyFilled />}}>{appLink.link}</Paragraph>
        <QRCode
          value={appLink.link}
          color="teal" />
        <Text italic>{strings.appDetail.appLinks.scanQrCode}</Text>
      </Space>
    </Modal>)

export default AppInstallationLinkModal
