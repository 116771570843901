import {Msal} from '../types/Msal'
import {HttpMethod} from '../types/HttpMethod'
import {MobileAppDetail, mobileAppDetailDecoder} from '../types/MobileAppDetail'
import {MaspError, MaspErrorType} from '../errors/MaspError'
import {arrayDecoder, Decoder} from 'json-decoder'
import {Platform} from '../types/Platform'
import {MobileAppListItem, mobileAppListItemDecoder} from '../types/MobileAppListItem'
import {GetMobileAppsFilter} from '../types/GetMobileAppsFilter'
import {
  ArtifactoryCredentials,
  artifactoryCredentialsDecoder,
} from '../types/ArtifactoryCredentials'
import {SubmissionResponse, submissionResponseDecoder} from '../types/SubmissionResponse'
import {AppUpdateResponse, appUpdateResponseDecoder} from '../types/AppUpdateResponse'

export interface NetworkServiceInterface {
  getMobileApps: (filter?: GetMobileAppsFilter) => Promise<MobileAppListItem[]>
  getMobileAppDetail: (platform: Platform, id: string) => Promise<MobileAppDetail>
  getArtifactoryUploadCredentials: () => Promise<ArtifactoryCredentials>
  submitMobileApp: (mobileAppDetail: MobileAppDetail) => Promise<SubmissionResponse>
  updateMobileApp: (
    id: string,
    platform: Platform,
    mobileApp: Partial<MobileAppDetail>
    ) => Promise<AppUpdateResponse>
  fetchMobileAppCsv: () => Promise<string>
  fetchStatusChangesCsv: () => Promise<string>
}

export class NetworkService implements NetworkServiceInterface {
  private readonly baseUrl: string
  private readonly msal: Msal

  constructor(baseUrl: string, msal: Msal) {
    this.baseUrl = baseUrl
    this.msal = msal
  }

  getMobileApps = async (filter?: GetMobileAppsFilter): Promise<MobileAppListItem[]> => {
    let url = `${this.baseUrl}/mobileApp`

    if (filter) {
      url += `?filter=${filter}`
    }

    const mobileApps = await this.fetchJson(
      url,
      HttpMethod.Get,
      arrayDecoder(mobileAppListItemDecoder),
    )

    return mobileApps
  }

  getMobileAppDetail = async (platform: Platform, id: string): Promise<MobileAppDetail> => {
    const mobileApp = await this.fetchJson(
      `${this.baseUrl}/mobileApp/${platform}/${id}`,
      HttpMethod.Get,
      mobileAppDetailDecoder,
    )
    return mobileApp
  }

  getArtifactoryUploadCredentials = async (): Promise<ArtifactoryCredentials> => {
    const artifactoryCredentials = await this.fetchJson(
      `${this.baseUrl}/artifactoryUploadCredentials`,
      HttpMethod.Get,
      artifactoryCredentialsDecoder,
    )
    return artifactoryCredentials
  }

  submitMobileApp = async (mobileAppDetail: MobileAppDetail): Promise<SubmissionResponse> => {
    const response = await this.fetchJson(
      `${this.baseUrl}/mobileApp`,
      HttpMethod.Post,
      submissionResponseDecoder,
      JSON.stringify(mobileAppDetail),
    )
    return response
  }

  updateMobileApp = async (
    id: string,
    platform: Platform,
    mobileApp: Partial<MobileAppDetail>,
  ): Promise<AppUpdateResponse> => {
    const response = await this.fetchJson(
      `${this.baseUrl}/mobileApp/${platform}/${id}`,
      HttpMethod.Put,
      appUpdateResponseDecoder,
      JSON.stringify(mobileApp),
    )
    return response
  }

  fetchMobileAppCsv = async(): Promise<string> => {
    const response = await this.fetchCsv(`${this.baseUrl}/mobileApp?format=csv`)
    return response
  }

  fetchStatusChangesCsv = async(): Promise<string> => {
    const response = await this.fetchCsv(`${this.baseUrl}/statusChanges`)
    return response
  }

  private getToken = async(): Promise<string | undefined> => {
    const {instance, scopes} = this.msal

    if (!this.msal.isActive) {
      // eslint-disable-next-line no-console
      console.warn('Running against local backend. Token is not needed.')
      return undefined
    }

    const [account] = instance.getAllAccounts()

    if (!account) {
      const msalAccountError = new MaspError(MaspErrorType.MsalAccount)
      // eslint-disable-next-line no-console
      console.error(msalAccountError.message)
      throw msalAccountError
    }

    try {
      const response = await instance.acquireTokenSilent({account, scopes})
      return response.accessToken
    } catch (error: any) {
      const msalTokenError = new MaspError(
        MaspErrorType.MsalToken,
        error.message,
      )
      // eslint-disable-next-line no-console
      console.error(msalTokenError.message)
      throw msalTokenError
    }
  }

  private fetchJson = async <T>(
    endpoint: string,
    method: HttpMethod,
    decoder: Decoder<T>,
    body?: string,
  ): Promise<T> => {
    const jwtToken = await this.getToken()

    const response = await fetch(endpoint, {
      method,
      headers: jwtToken ? {Authorization: `Bearer ${jwtToken}`} : undefined,
      body,
    }).catch((error: any) => {
      const networkError = new MaspError(
        MaspErrorType.Network,
        error.message,
      )
      // eslint-disable-next-line no-console
      console.error(`🚨 ${networkError.message}`)
      throw networkError
    })

    if (!response.ok) {
      const {status} = response
      let responseJson: any
      try {
        responseJson = await response?.json()
      } catch {
        responseJson = undefined
      }

      let errorDetail = `status code: ${status}`
      errorDetail += ` // detail: ${JSON.stringify(responseJson)}`
      const networkError = new MaspError(MaspErrorType.Network, errorDetail)
      // eslint-disable-next-line no-console
      console.error(`🚨 ${networkError.message}`)
      throw networkError
    }

    const responseJson = await response.json().catch((error: any) => {
      const jsonError = new MaspError(
        MaspErrorType.JsonParsing,
        error.message,
      )
      // eslint-disable-next-line no-console
      console.error(`🚨 ${jsonError.message}`)
      throw jsonError
    })

    const decodedResponse = await decoder
      .decodeAsync(responseJson)
      .catch((error: any) => {
        const jsonDecodingError = new MaspError(
          MaspErrorType.JsonDecoding,
          error.message,
        )
        // eslint-disable-next-line no-console
        console.error(`🚨 ${jsonDecodingError.message}`)
        throw jsonDecodingError
      })

    return decodedResponse
  }

  private fetchCsv = async (
    endpoint: string,
  ): Promise<string> => {
    const jwtToken = await this.getToken()

    const response = await fetch(endpoint, {
      headers: jwtToken ? {Authorization: `Bearer ${jwtToken}`} : undefined,
    }).catch((error: any) => {
      const networkError = new MaspError(
        MaspErrorType.Network,
        error.message,
      )
      // eslint-disable-next-line no-console
      console.error(`🚨 ${networkError.message}`)
      throw networkError
    })

    if (!response.ok) {
      const {status} = response
      let responseJson: any
      try {
        responseJson = await response?.json()
      } catch {
        responseJson = undefined
      }

      let errorDetail = `status code: ${status}`
      errorDetail += ` // detail: ${JSON.stringify(responseJson)}`
      const networkError = new MaspError(MaspErrorType.Network, errorDetail)
      // eslint-disable-next-line no-console
      console.error(`🚨 ${networkError.message}`)
      throw networkError
    }

    const responseCsv = await response.text().catch((error: any) => {
      const csvError = new MaspError(
        MaspErrorType.CsvParsing,
        error.message,
      )
      // eslint-disable-next-line no-console
      console.error(`🚨 ${csvError.message}`)
      throw csvError
    })

    return responseCsv
  }
}
