import {Decoder, stringDecoder} from 'json-decoder'

export enum MobileAppStatusName {
  RequestReview = 'Request review',
  Build = 'Build',
  VulnerabilityScanning = 'Vulnerability scanning',
  TestFlightGooglePlay = 'TestFlight / Google Play',
  PendingOwnerAction = 'Pending owner action',
  IQOQTest = 'IQ/OQ test',
  DivisionalApproval = 'Divisional approval',
  ReleaseMetadata = 'Release metadata',
  StoreSubmission = 'Store submission',
  StoreReview = 'Store review',
  PendingRelease = 'Pending release',
  MDMDeployment = 'MDM deployment',
  Released = 'Released',
  Retired = 'Retired',
  Cancelled = 'Cancelled',
  Archived = 'Archived'
}

const getMobileAppStatusNameFromString = (value: string): MobileAppStatusName => {
  switch (value) {
    case 'Request review':
      return MobileAppStatusName.RequestReview
    case 'Build':
      return MobileAppStatusName.Build
    case 'Vulnerability scanning':
      return MobileAppStatusName.VulnerabilityScanning
    case 'TestFlight / Google Play':
      return MobileAppStatusName.TestFlightGooglePlay
    case 'Pending owner action':
      return MobileAppStatusName.PendingOwnerAction
    case 'IQ/OQ test':
      return MobileAppStatusName.IQOQTest
    case 'Divisional approval':
      return MobileAppStatusName.DivisionalApproval
    case 'Release metadata':
      return MobileAppStatusName.ReleaseMetadata
    case 'Store submission':
      return MobileAppStatusName.StoreSubmission
    case 'Store review':
      return MobileAppStatusName.StoreReview
    case 'Pending release':
      return MobileAppStatusName.PendingRelease
    case 'MDM deployment':
      return MobileAppStatusName.MDMDeployment
    case 'Released':
      return MobileAppStatusName.Released
    case 'Retired':
      return MobileAppStatusName.Retired
    case 'Cancelled':
      return MobileAppStatusName.Cancelled
    case 'Archived':
      return MobileAppStatusName.Archived
    default:
      throw new Error(
        `Could not decode ${value} to MobileAppStatusName. 
        Allowed values are: ${Object.values(MobileAppStatusName)}`,
      )
  }
}

export const mobileAppStatusNameDecoder: Decoder<MobileAppStatusName> = stringDecoder.map(
  getMobileAppStatusNameFromString,
)
