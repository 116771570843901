import React from 'react'
import './index.css'
import AppRouter from './AppRouter'
import {Msal} from './types/Msal'
import {MsalProvider, MsalAuthenticationTemplate} from '@azure/msal-react'
import {InteractionType} from '@azure/msal-browser'
import {Spinner} from './components/Spinner'
import {strings} from './localization/strings'
import {FailureCard} from './components/FailureCard/FailureCard'

export type AppProps = {
  msal: Msal
}

const msalLoadingComponent = () => <Spinner tip={strings.loadingMessage.authentication} />
const msalErrorComponent = () => (
  <FailureCard title={strings.msal.error} detail={strings.error.unknown} />
)

const App: React.FC<AppProps> = ({msal}) => (msal.isActive ? (
  <MsalProvider instance={msal.instance}>
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={{scopes: msal.scopes}}
      loadingComponent={msalLoadingComponent}
      errorComponent={msalErrorComponent}
    >
      <AppRouter />
    </MsalAuthenticationTemplate>
  </MsalProvider>
) : (
  <AppRouter />
))

export default App
