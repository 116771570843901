import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react'
import AppSubmissionForm from '../components/AppSubmission/AppSubmissionForm'
import {useLocation} from 'react-router'
import {Spinner} from '../components/Spinner'
import {NetworkService} from '../services/NetworkService'
import {config} from '../config/config'
import {MobileAppDetail} from '../types/MobileAppDetail'
import {getAppIdentifierPrefix} from '../types/DistributionMethod'
import {getPublisher} from '../types/Publisher'
import {MobileAppStatusName} from '../types/MobileAppStatusName'
import {customDeepmerge} from '../helpers/CustomDeepMerge'
import {SubmissionResult, SubmissionErrorType} from '../types/SubmissionResult'
import AppSubmissionResult from '../components/AppSubmission/AppSubmissionResult'
import {strings} from '../localization/strings'

const AppSubmission: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loadingMessage, setLoadingMessage] = useState<string>(strings.app.loading)
  const [initialFormData, setInitialFormData] = useState<Partial<MobileAppDetail>>({})
  const [isAppUpdate, setIsAppUpdate] = useState<boolean>(false)
  const [previousAppVersion, setPreviousAppVersion] = useState<string>('')
  const [submissionResult, setSubmissionResult] = useState<SubmissionResult | null>(null)

  const {state} = useLocation()
  const {current: networkService} = useRef(new NetworkService(config.backendBaseUrl, config.msal))

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const fetchAppDetail = useCallback(async () => {
    // INITIAL RELEASE REQUEST
    if (!state || !state.id || !state.platform) {
      return
    }

    // NEW RELEASE REQUEST
    const {id: appId, platform} = state
    try {
      setIsLoading(true)
      // Remove id from existing app detail
      const {id, ...appDetail} = await networkService.getMobileAppDetail(platform, appId)
      setInitialFormData(appDetail)
      setPreviousAppVersion(appDetail.version)
      setIsAppUpdate(true)
    } catch (error: any) {
      setSubmissionResult({status: 'error', error, errorType: SubmissionErrorType.FetchAppDetail})
      // eslint-disable-next-line no-console
      console.error(`🚨 ${error.message}`)
    } finally {
      setIsLoading(false)
    }
  }, [networkService, state])

  useEffect(() => {
    fetchAppDetail()
  }, [fetchAppDetail])

  const handleSubmit = useCallback(async (finalFormData: Partial<MobileAppDetail>) => {
    window.scrollTo(0, 0)

    const submissionData = customDeepmerge(initialFormData, finalFormData) as MobileAppDetail
    setInitialFormData(submissionData)
    if (!isAppUpdate) {
      const appIdentifierPrefix = getAppIdentifierPrefix(submissionData.distributionMethod)
      submissionData.appIdentifier = `${appIdentifierPrefix}${submissionData.appIdentifier}`
    }
    submissionData.jiraIssue = {}
    submissionData.screenshots = []
    submissionData.status = {name: MobileAppStatusName.RequestReview}

    if (!submissionData.publisher) {
      const {distributionMethod, division, platform} = submissionData
      submissionData.publisher = getPublisher(distributionMethod, division, platform)
    }

    try {
      setIsLoading(true)
      setLoadingMessage(strings.loadingMessage.submittingForm)
      const response = await networkService.submitMobileApp(submissionData)
      setSubmissionResult({status: 'success', response})
    } catch (error: any) {
      setSubmissionResult({status: 'error', error, errorType: SubmissionErrorType.FormSubmission})
      // eslint-disable-next-line no-console
      console.error(`🚨 ${error.message}`)
    } finally {
      setIsLoading(false)
    }
  }, [initialFormData, isAppUpdate, networkService])

  const handleRetry = useCallback(() => {
    setSubmissionResult(null)
    if (submissionResult?.errorType === SubmissionErrorType.FetchAppDetail) {
      fetchAppDetail()
    }
  }, [fetchAppDetail, submissionResult])

  if (isLoading) {
    return <Spinner tip={loadingMessage} />
  }

  if (submissionResult) {
    return (
      <AppSubmissionResult
        result={submissionResult}
        onRetry={handleRetry}
      />
    )
  }

  return (
    <AppSubmissionForm
      isAppUpdate={isAppUpdate}
      initialFormData={initialFormData}
      previousAppVersion={previousAppVersion}
      onSubmit={handleSubmit}
    />
  )
}

export default AppSubmission
