import {strings} from '../localization/strings'
import {AppLink} from '../types/AppLink'
import {MobileAppLinks} from '../types/MobileAppLinks'
import {Platform} from '../types/Platform'

export class AppLinksHelper {
  static getAppLinks = (links: MobileAppLinks, platform: Platform): AppLink[] => {
    const {appLinks: titles} = strings.appDetail

    const {
      appManager,
      appPublicInstallation,
      artifactoryRepository,
      ciCdPlatform,
      divisionalTracking,
      internalAppStore,
      sourceCodeRepository,
      vulnerabilityScan,
    } = links

    const appLinks: AppLink[] = []

    const appManagerTitle = platform === Platform.Ios
      ? titles.appManagerIos
      : titles.appManagerAndroid
    appManager && appLinks.push(
      {title: appManagerTitle, link: appManager, isAppInstallationLink: false})

    if (appPublicInstallation) {
      appLinks.push({
        title: titles.appInstallationLink,
        link: appPublicInstallation,
        isAppInstallationLink: true,
      })
    } else if (internalAppStore) {
      appLinks.push({
        title: titles.appInstallationLink,
        link: internalAppStore,
        isAppInstallationLink: true,
      })
    }

    artifactoryRepository && appLinks.push({
      title: titles.artifactoryRepository,
      link: artifactoryRepository,
      isAppInstallationLink: false,
    })

    ciCdPlatform && appLinks.push({
      title: titles.bitrise, link: ciCdPlatform, isAppInstallationLink: false,
    })

    divisionalTracking && appLinks.push({
      title: titles.divisionalTracking,
      link: divisionalTracking,
      isAppInstallationLink: false,
    })

    sourceCodeRepository && appLinks.push({
      title: titles.sourceCode, link: sourceCodeRepository, isAppInstallationLink: false,
    })

    vulnerabilityScan && appLinks.push({
      title: titles.vulnerabilityScan,
      link: vulnerabilityScan,
      isAppInstallationLink: false,
    })

    return appLinks
  }
}
