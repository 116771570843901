import React, {memo, useState} from 'react'
import {
  CheckOutlined,
  CloseOutlined,
  SettingOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import {Button, Dropdown, message} from 'antd'
import type {MenuProps} from 'antd'
import {MobileAppStatusName} from '../../types/MobileAppStatusName'
import {MobileAppStatusUI} from '../../types/MobileAppStatusUI'
import {Platform} from '../../types/Platform'
import {styles} from './StatusSettings.styles'
import {useStatusSettings} from './StatusSettings.hooks'
import AppReleaseDatePicker from '../AppReleaseDate/AppReleaseDatePicker'

export type AppStatusChangeCallbackParams = (
  id: string,
  status: MobileAppStatusName,
  statusUI?: MobileAppStatusUI,
  isRip?: boolean,
  releaseDate?: Date
) => void

export type StatusSettingsProps = {
    appId: string
    platform: Platform
    currentStatus: MobileAppStatusName
    onAppStatusChange: AppStatusChangeCallbackParams
}

const StatusSettings: React.FC<StatusSettingsProps> = ({
  appId,
  platform,
  currentStatus,
  onAppStatusChange,
}) => {
  const {
    isLoading,
    isSuccess,
    error,
    selectedStatus,
    submitStatusChange,
  } = useStatusSettings(appId, platform, currentStatus, onAppStatusChange)

  const [isDatePickerModalOpen, setIsDatePickerModalOpen] = useState(false)

  const items: MenuProps['items'] = [
    {
      label: MobileAppStatusName.Build,
      key: MobileAppStatusName.Build,
    },
    {
      label: MobileAppStatusName.VulnerabilityScanning,
      key: MobileAppStatusName.VulnerabilityScanning,
    },
    {
      label: MobileAppStatusName.TestFlightGooglePlay,
      key: MobileAppStatusName.TestFlightGooglePlay,
    },
    {
      label: MobileAppStatusName.PendingOwnerAction,
      key: MobileAppStatusName.PendingOwnerAction,
    },
    {
      label: MobileAppStatusName.IQOQTest,
      key: MobileAppStatusName.IQOQTest,
    },
    {
      label: MobileAppStatusName.DivisionalApproval,
      key: MobileAppStatusName.DivisionalApproval,
    },
    {
      label: MobileAppStatusName.ReleaseMetadata,
      key: MobileAppStatusName.ReleaseMetadata,
    },
    {
      label: MobileAppStatusName.StoreSubmission,
      key: MobileAppStatusName.StoreSubmission,
    },
    {
      label: MobileAppStatusName.StoreReview,
      key: MobileAppStatusName.StoreReview,
    },
    {
      label: MobileAppStatusName.PendingRelease,
      key: MobileAppStatusName.PendingRelease,
    },
    {
      label: MobileAppStatusName.MDMDeployment,
      key: MobileAppStatusName.MDMDeployment,
    },
    {
      type: 'divider',
    },
    {
      label: MobileAppStatusName.Released,
      key: MobileAppStatusName.Released,
    },
    {
      label: MobileAppStatusName.Cancelled,
      key: MobileAppStatusName.Cancelled,
    },

  ]

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    e.domEvent.stopPropagation()
    const newStatus = e.key as MobileAppStatusName
    if (newStatus === selectedStatus) {
      return
    }
    if (newStatus === MobileAppStatusName.Released) {
      setIsDatePickerModalOpen(true)
      return
    }
    submitStatusChange(newStatus)
  }

  const handleDatePickerChange = (releaseDate?: Date) => {
    setIsDatePickerModalOpen(false)
    if (releaseDate) {
      submitStatusChange(MobileAppStatusName.Released, releaseDate)
    }
  }

  const getIcon = () => {
    if (isLoading) {
      return <SyncOutlined spin />
    }
    if (isSuccess) {
      return <CheckOutlined style={styles.checkIcon} />
    }
    if (error) {
      return <CloseOutlined style={styles.closeIcon} />
    }
    return <SettingOutlined />
  }

  if (error) {
    message.error(error.message)
  }

  const statusSelectionDropdown = (
    <Dropdown
      menu={{
        items,
        onClick: handleMenuClick,
        selectable: true,
        selectedKeys: [selectedStatus],
      }}
      trigger={isLoading || isSuccess || error ? [] : ['click']}
    >
      <Button
        onClick={(event) => event.stopPropagation()}
        style={styles.dropdownButton}
        size="small"
      >
        {getIcon()}
      </Button>
    </Dropdown>
  )

  return (
    <div>
      { statusSelectionDropdown }
      { isDatePickerModalOpen &&
      <AppReleaseDatePicker onDatePickerChange={handleDatePickerChange} />
      }
    </div>
  )
}

export default memo(StatusSettings)
