import {objectDecoder, oneOfDecoders, stringDecoder, undefinedDecoder} from 'json-decoder'

export type MobileAppDemo = {
  instructions: string
  username?: string
  password?: string
}

export const mobileAppDemoDecoder = objectDecoder<MobileAppDemo>({
  instructions: stringDecoder,
  username: oneOfDecoders(stringDecoder, undefinedDecoder),
  password: oneOfDecoders(stringDecoder, undefinedDecoder),
})
