import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export class DateHelper {
  static getFormattedDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      timeZone: 'UTC',
    }

    return Intl.DateTimeFormat('en-US', options).format(date)
  }

  static getConvertedLocalDateToUTC = (date: Date): Date => {
    const localDate = dayjs(date)
    const utcDate = dayjs.utc(`${localDate.year()}-${localDate.month() + 1}-${localDate.date()}`)
      .toDate()
    return utcDate
  }
}
