import {strings} from '../localization/strings'
import {AppPublishingInformation} from '../types/AppPublishingInformation'

export class AppPublishingInformationHelper {
  static appPublishingInformations: AppPublishingInformation[] = [
    {title: strings.submission.customMobileAppDescription},
    {
      title: strings.submission.hhMobileAppDescription,
      link: 'https://go.merck.com/submitapp',
      linkText: strings.submission.mobileAppsServiceDeskLinkText,
    },
    {
      title: strings.submission.ahMobileAppDescription,
      link: 'https://mahsupport.atlassian.net/servicedesk/customer/portals',
      linkText: strings.submission.dmsServiceDeskLinkText,
    },
    {
      title: strings.submission.cotsMobileAppDescription,
      link: 'https://go.msd.com/publish-webclip-cots-app-request',
      linkText: strings.submission.webclipsCotsLinkText,
    },
  ]
}
