export const fieldName = {
  name: 'name',
  version: 'version',
  appAvailability: 'distributionMethod',
  platform: 'platform',
  appIdentifier: 'appIdentifier',
  contactEmail: 'contactEmail',
  description: 'description',
  division: 'division',
  ciShortName: 'cmdbId',
  assessments: 'assessments',
  riskAssessment: 'riskId',
  privacyAssessment: 'privacyId',
  demo: 'demo',
  instructions: 'instructions',
  username: 'username',
  password: 'password',
  testerEmails: 'testerEmails',
  links: 'links',
  sourceCodeRepository: 'sourceCodeRepository',
  artifactoryRepository: 'artifactoryRepository',
  packagingType: 'packagingType',
}
