import {objectDecoder, oneOfDecoders, stringDecoder, undefinedDecoder} from 'json-decoder'
import {MobileAppStatusName, mobileAppStatusNameDecoder} from './MobileAppStatusName'

export type MobileAppStatus = {
  name: MobileAppStatusName
  errorMessage?: string
}

export const mobileAppStatusDecoder = objectDecoder<MobileAppStatus>({
  name: mobileAppStatusNameDecoder,
  errorMessage: oneOfDecoders(stringDecoder, undefinedDecoder),
})
