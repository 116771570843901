import React, {useCallback, useRef, useState} from 'react'
import FileDragger from '../shared/FileDragger/FileDragger'
import ArtifactoryUploadService from '../../services/ArtifactoryUploadService'
import {config} from '../../config/config'
import {UploadStatus} from '../../types/UploadStatus'
import {Platform, getSupportedFileTypes} from '../../types/Platform'
import {StringHelper} from '../../helpers/StringHelper'
import {NetworkService} from '../../services/NetworkService'

export type BinaryUploaderProps = {
  platform: Platform
  appId: string
  appVersion: string
  onCompletion: (success: boolean, uploadUrl?: string) => void
}

const BinaryUploader: React.FC<BinaryUploaderProps> = (
  {platform, appId, appVersion, onCompletion},
) => {
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>(UploadStatus.Idle)

  const {current: networkService} = useRef(new NetworkService(config.backendBaseUrl, config.msal))

  const getRepositoryPath = useCallback(
    (file: File): string => {
      const fileExtension = file.name.split('.').pop() ?? ''
      return StringHelper.getArtifactoryRepositoryPath(
        platform,
        appId,
        appVersion,
        fileExtension,
      )
    },
    [appId, appVersion, platform],
  )

  const handleFileUpload = useCallback(
    async (file: File, onProgress: (percent: number) => void) => {
      try {
        setUploadStatus(UploadStatus.Uploading)
        const url = `${config.artifactoryBaseUrl}/${getRepositoryPath(file)}`
        const credentials = await networkService.getArtifactoryUploadCredentials()
        await ArtifactoryUploadService.uploadFile(
          credentials,
          file,
          url,
          onProgress,
        )
        setUploadStatus(UploadStatus.Success)
        onCompletion(true, url)
      } catch (error) {
        setUploadStatus(UploadStatus.Error)
        onCompletion(false)
      }
    },
    [getRepositoryPath, networkService, onCompletion],
  )

  const handleFileRemove = () => {
    onCompletion(false)
    setUploadStatus(UploadStatus.Idle)
  }

  return (
    <FileDragger
      onFileUpload={handleFileUpload}
      onFileRemove={handleFileRemove}
      uploadStatus={uploadStatus}
      acceptedFileExtensions={getSupportedFileTypes(platform)}
    />
  )
}

export default BinaryUploader
