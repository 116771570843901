import React from 'react'
import {Form, Input, Select, Space, Typography} from 'antd'
import {LockOutlined, UserOutlined} from '@ant-design/icons'
import AppSubmissionFormHeader from './AppSubmissionFormHeader'
import {strings} from '../../localization/strings'
import {styles} from './AppSubmissionForm.styles'
import {regexPattern} from '../../types/RegexPattern'
import {Platform, getDemoInfoLink, getStoreName} from '../../types/Platform'
import {fieldName} from './AppSubmissionFieldNames'
import {useWatch} from 'antd/es/form/Form'

const TestingSection: React.FC = () => {
  const form = Form.useFormInstance()

  const {Title, Text} = Typography
  const {Item} = Form
  const {submission: texts} = strings
  const {label, placeholder, validation: validationMessage} = texts

  const platform = useWatch<Platform>(fieldName.platform, form)
  const storeName = getStoreName(platform)
  const demoInfoLink = getDemoInfoLink(platform)

  const maxTestersCount = 15

  const emailsValidator = (_rule: any, value: any) => {
    const isEmailsValid = value.every((tag: string) =>
      regexPattern.email.test(tag),
    )

    if (!isEmailsValid) {
      return Promise.reject(validationMessage.invalidEmails)
    }
    return Promise.resolve()
  }

  return (
    <>
      <AppSubmissionFormHeader title={texts.testing} />
      <div style={styles.demoContainer}>
        <Title level={5}>{texts.demoTitle(storeName)}</Title>

        <h4>{texts.demoInstructionsTitle}</h4>
        <Text style={styles.fieldSummary}>
          {texts.demoInstructionsSummary(storeName)}
          {demoInfoLink && (
            <>
              {texts.forMoreInfo}
              <a href={demoInfoLink} target="_blank" rel="noopener noreferrer">{texts.clickHere}</a>
            </>
          )}
        </Text>

        <Item
          name={[fieldName.demo, fieldName.instructions]}
          rules={[{required: true, message: validationMessage.demoInstructionsRequired}]}
        >
          <Input.TextArea rows={3} />
        </Item>

        <h4>{texts.demoCredentialsTitle}</h4>
        <Text style={styles.fieldSummary}>
          <ul>
            <li>{texts.demoCredentialsSummary}</li>
            <li>{texts.demoCredentialsWarning1}<strong>{texts.demoCredentialsWarning2}</strong></li>
          </ul>
        </Text>

        <Item>
          <Space.Compact block>
            <Item
              name={[fieldName.demo, fieldName.username]}
              noStyle
              rules={[{required: false}]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder={label.username}
                style={styles.compactInput}
              />
            </Item>
            <Item
              name={[fieldName.demo, fieldName.password]}
              noStyle
              rules={[{required: false}]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder={label.password}
                style={styles.compactInput}
              />
            </Item>
          </Space.Compact>
        </Item>
      </div>

      <h4>{texts.testersTitle}</h4>
      <Text style={styles.fieldSummary}>
        <ul>
          <li>{texts.testersMaxCount(maxTestersCount)}</li>
          <li>{texts.testersAccountLink(storeName)}</li>
        </ul>
      </Text>

      <Item
        name={fieldName.testerEmails}
        validateFirst
        rules={[
          {
            required: true, message: validationMessage.testerEmailsRequired,
          },
          {
            validator: emailsValidator,
          },
        ]}
      >
        <Select
          mode="tags"
          placeholder={placeholder.testerEmails}
          maxCount={maxTestersCount}
          suffixIcon={null}
          tokenSeparators={[',']}
          dropdownStyle={styles.hidden}
        />

      </Item>
    </>
  )
}

export default TestingSection
