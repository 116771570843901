import {Platform} from '../types/Platform'

export class StringHelper {
  static getAppVersionWithBuildNumber = (appVersion: string, buildNumber?: string): string => {
    let appVersionWithBuildNumber = `v${appVersion}`

    if (buildNumber) {
      appVersionWithBuildNumber += ` (${buildNumber})`
    }

    return appVersionWithBuildNumber
  }

  static getArtifactoryRepositoryPath = (
    platform: Platform,
    appId: string,
    appVersion: string,
    fileExtension: string,
  ): string =>
    `${platform}/${appId}/${appVersion}/${appId}-unpublished.${fileExtension}`

  static getIsidFromUsername = (username: string): string | undefined => {
    const nameParts = username.split('@')

    if (nameParts.length < 2) {
      return undefined
    }
    return nameParts[0]
  }
}
