import {Decoder, stringDecoder} from 'json-decoder'

export enum DistributionMethod {
  ABM = 'ABM',
  Enterprise = 'Enterprise',
  Public = 'Public',
  Unlisted = 'Unlisted'
}

const getDistributionMethodFromString = (value: string): DistributionMethod => {
  switch (value) {
    case 'ABM':
      return DistributionMethod.ABM
    case 'Enterprise':
      return DistributionMethod.Enterprise
    case 'Public':
      return DistributionMethod.Public
    case 'Unlisted':
      return DistributionMethod.Unlisted
    default:
      throw new Error(
        // eslint-disable-next-line max-len
        `Could not decode ${value} to DistributionMethod. Allowed values are: ${Object.values(DistributionMethod)}`,
      )
  }
}

export const getAppIdentifierPrefix = (distributionMethod: DistributionMethod) : string => {
  switch (distributionMethod) {
    case DistributionMethod.ABM:
      return 'com.msd.internal.'
    case DistributionMethod.Public:
      return 'com.msd.'
    default:
      return ''
  }
}

// eslint-disable-next-line max-len
export const distributionMethodDecoder: Decoder<DistributionMethod> = stringDecoder.map(getDistributionMethodFromString)
