import React from 'react'
import {strings} from '../../localization/strings'
import AppSubmissionFormHeader from './AppSubmissionFormHeader'
import {Division} from '../../types/Division'
import {Form, Input, Radio} from 'antd'
import {InfoCircleOutlined, WarningOutlined} from '@ant-design/icons'
import {useWatch} from 'antd/es/form/Form'
import {styles} from './AppSubmissionForm.styles'
import {fieldName} from './AppSubmissionFieldNames'

export type ComplianceSectionProps = {
  isAppUpdate: boolean
}

const ComplianceSection: React.FC<ComplianceSectionProps> = ({isAppUpdate}) => {
  const form = Form.useFormInstance()

  const {label, validation: validationMessage} = strings.submission

  const division = useWatch(fieldName.division, form)

  return (
    <>
      <AppSubmissionFormHeader title={strings.submission.compliance} />

      <Form.Item
        label={label.division}
        name={fieldName.division}
        rules={[{required: true, message: validationMessage.divisionRequired}]}
        extra={division && (
          <span style={styles.warningExtra}>
            <WarningOutlined style={styles.iconPrefix} />
            {strings.submission.divisionWarning}
            {/* TODO: Add division contact info confluence link once ready */}
          </span>
        )}
      >
        <Radio.Group disabled={isAppUpdate}>
          <Radio.Button value={Division.HH}>{Division.HH}</Radio.Button>
          <Radio.Button value={Division.AH}>{Division.AH}</Radio.Button>
          <Radio.Button value={Division.MMD}>{Division.MMD}</Radio.Button>
          <Radio.Button value={Division.MRL}>{Division.MRL}</Radio.Button>
          <Radio.Button value={Division.Other}>{Division.Other}</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={label.ciShortName}
        name={fieldName.ciShortName}
        rules={[{required: true, message: validationMessage.ciNameRequired}]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={label.riskAssessment}
        name={[fieldName.assessments, fieldName.riskAssessment]}
        rules={[{required: true, message: validationMessage.riskAssessmentRequired}]}
        extra={
          <span style={styles.infoExtra}>
            <InfoCircleOutlined style={styles.iconPrefix} />
            {strings.submission.riskAssessmentInfo}
          </span>
        }
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={label.privacyAssessment}
        name={[fieldName.assessments, fieldName.privacyAssessment]}
        rules={[{required: true, message: validationMessage.privacyAssessmentRequired}]}
      >
        <Input />
      </Form.Item>
    </>
  )
}

export default ComplianceSection
