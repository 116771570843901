// eslint-disable-next-line import/named
import axios, {AxiosRequestConfig} from 'axios'
import {MaspError, MaspErrorType} from '../errors/MaspError'
import {ArtifactoryCredentials} from '../types/ArtifactoryCredentials'

class ArtifactoryUploadService {
  static uploadFile = async(
    credentials: ArtifactoryCredentials,
    file: File,
    url: string,
    onProgress: (percent: number) => void,
  ): Promise<void> => {
    const {username, password, apiKey} = credentials
    const requestConfig: AxiosRequestConfig = {
      auth: {
        username,
        password,
      },
      headers: {
        'Content-Type': 'application/octet-stream',
        'X-Merck-APIKey': apiKey,
      },
    }

    try {
      await axios.put(url, file, {
        ...requestConfig,
        onUploadProgress: (progressEvent) => {
          const {total, loaded} = progressEvent
          if (total) {
            const percent = (loaded / total) * 100
            onProgress(Math.floor(percent))
          }
        },
      })
    } catch (error: any) {
      const errorType = (axios.isAxiosError(error))
        ? MaspErrorType.Network
        : MaspErrorType.UploadFailed

      const uploadError = new MaspError(
        errorType,
        error.message,
      )
      // eslint-disable-next-line no-console
      console.error(`🚨 ${uploadError.message}`)
      throw uploadError
    }
  }
}

export default ArtifactoryUploadService
