/* eslint-disable react/require-default-props */
import React, {CSSProperties, ReactNode} from 'react'
import {Tag} from 'antd'
import {Link} from 'react-router-dom'
import {styles} from './LinkTag.styles'

export type LinkTagProps = {
    url: string,
    title: string,
    icon?: ReactNode,
    tagColor?: string
    tagStyle?: CSSProperties
}

const LinkTag: React.FC<LinkTagProps> = ({
  url,
  title,
  icon,
  tagColor,
  tagStyle,
}) => (
  <Link to={url} target="_blank" onClick={(event) => event.stopPropagation()}>
    <Tag color={tagColor} style={tagStyle}>
      {icon && <span style={styles.icon}>{icon}</span>}
      {title}
    </Tag>
  </Link>
)

export default LinkTag
