import {objectDecoder, oneOfDecoders, stringDecoder, undefinedDecoder} from 'json-decoder'
import {Division, divisionDecoder} from './Division'
import {MobileAppStatusUI, mobileAppStatusUIDecoder} from './MobileAppStatusUI'
import {Platform, platformDecoder} from './Platform'
import {MobileAppStatus, mobileAppStatusDecoder} from './MobileAppStatus'
import {JiraIssue, jiraIssueDecoder} from './JiraIssue'

export type MobileAppListItem = {
  id: string
  division: Division
  icon?: string
  jiraIssue: JiraIssue
  name: string
  platform: Platform
  status: MobileAppStatus
  statusUI?: MobileAppStatusUI
  version: string
}

export const mobileAppListItemDecoder = objectDecoder<MobileAppListItem>({
  id: stringDecoder,
  division: divisionDecoder,
  icon: oneOfDecoders(stringDecoder, undefinedDecoder),
  jiraIssue: jiraIssueDecoder,
  name: stringDecoder,
  platform: platformDecoder,
  status: mobileAppStatusDecoder,
  statusUI: oneOfDecoders(mobileAppStatusUIDecoder, undefinedDecoder),
  version: stringDecoder,
})
