import {objectDecoder, stringDecoder} from 'json-decoder'
import {MobileAppDetail, mobileAppDetailDecoder} from './MobileAppDetail'

export type AppUpdateResponse = {
    message: string,
    mobileApp: MobileAppDetail,
}

export const appUpdateResponseDecoder = objectDecoder<AppUpdateResponse>({
  message: stringDecoder,
  mobileApp: mobileAppDetailDecoder,
})
