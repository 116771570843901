import React, {useState} from 'react'
import dayjs from 'dayjs'
import type {DatePickerProps} from 'antd'
import {DatePicker, Modal, Space, Typography} from 'antd'
import {strings} from '../../localization/strings'
import {DateHelper} from '../../helpers/DateHelper'

export type AppReleaseDatePickerProps = { onDatePickerChange: (releaseDate?: Date) => void }

const AppReleaseDatePicker: React.FC<AppReleaseDatePickerProps> = ({onDatePickerChange}) => {
  const {Text} = Typography
  const [releaseDate, setReleaseDate] = useState<Date>(dayjs().toDate())

  const handleOk = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()
    if (releaseDate) {
      onDatePickerChange(releaseDate)
    }
  }

  const handleCancel = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()
    onDatePickerChange()
  }

  const datePickerOnChange: DatePickerProps['onChange'] = (date, _) => {
    if (date) {
      setReleaseDate(DateHelper.getConvertedLocalDateToUTC(date.toDate()))
    } else {
      setReleaseDate(date)
    }
  }

  return (
    <Modal
      title={strings.submission.label.appReleaseDate}
      open
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{disabled: !releaseDate}}
    >
      <Space direction="vertical">
        <DatePicker
          maxDate={dayjs()}
          defaultValue={dayjs()}
          onChange={datePickerOnChange}
          onClick={(event) => event.stopPropagation()} />
        { !releaseDate &&
          <Text type="danger">{strings.submission.validation.releaseDateRequired}</Text>
        }
      </Space>
    </Modal>
  )
}

export default AppReleaseDatePicker

