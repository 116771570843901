import React from 'react'
import {Col, Row, Typography} from 'antd'
import {MobileAppDetail} from '../../types/MobileAppDetail'
import {strings} from '../../localization/strings'
import {DateHelper} from '../../helpers/DateHelper'
import AppGeneralInformationItem from './AppGeneralInformationItem'

const {Title} = Typography

export type AppGeneralInformationSectionProps = {
    mobileApp: MobileAppDetail
}

const AppGeneralInformationSection: React.FC<AppGeneralInformationSectionProps> = ({mobileApp}) => {
  const {
    division,
    appIdentifier,
    cmdbId,
    publisher,
    distributionMethod,
    lastReleaseDate,
    contactEmail,
    assessments,
  } = mobileApp

  const {appGeneralInformation: texts} = strings.appDetail

  return (
    <>
      <Title level={4}> {texts.title} </Title>
      <Row>
        <Col span={7}>
          <AppGeneralInformationItem title={texts.appIdentifier} value={appIdentifier} />
          <AppGeneralInformationItem title={texts.division} value={division} />
          <AppGeneralInformationItem title={texts.appDistribution} value={distributionMethod} />
          <AppGeneralInformationItem title={texts.publisher} value={publisher} />
          <AppGeneralInformationItem title={texts.contact} value={contactEmail} />
        </Col>
        <Col span={7}>
          <AppGeneralInformationItem title={texts.cmdbId} value={cmdbId} />
          <AppGeneralInformationItem title={texts.riskId} value={assessments.riskId} />
          <AppGeneralInformationItem title={texts.privacyId} value={assessments.privacyId} />
          {lastReleaseDate &&
          <AppGeneralInformationItem
            title={texts.lastReleaseDate}
            value={DateHelper.getFormattedDate(lastReleaseDate)} />
          }
        </Col>
      </Row>
    </>
  )
}

export default AppGeneralInformationSection
