import {useCallback, useRef, useState} from 'react'
import {MobileAppStatusName} from '../../types/MobileAppStatusName'
import {NetworkService} from '../../services/NetworkService'
import {config} from '../../config/config'
import {Platform} from '../../types/Platform'
import {AppStatusChangeCallbackParams} from './StatusSettings'

type StatusSettingsResult = {
    isLoading: boolean
    isSuccess: boolean,
    error: any,
    selectedStatus: MobileAppStatusName,
    submitStatusChange: (newStatus: MobileAppStatusName, releaseDate?: Date) => void
}

export const useStatusSettings = (
  appId: string,
  platform: Platform,
  currentStatus: MobileAppStatusName,
  onAppStatusChange: AppStatusChangeCallbackParams,
): StatusSettingsResult => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [error, setError] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState<MobileAppStatusName>(currentStatus)

  const {current: networkService} = useRef(new NetworkService(config.backendBaseUrl, config.msal))

  const submitStatusChange = useCallback((newStatus: MobileAppStatusName, releaseDate?: Date) => {
    setIsLoading(true)
    const response = networkService.updateMobileApp(
      appId,
      platform,
      {lastReleaseDate: releaseDate, status: {name: newStatus}},
    )
    response
      .then((data) => {
        setSelectedStatus(newStatus)
        setIsSuccess(true)
        onAppStatusChange(
          appId,
          newStatus,
          data.mobileApp.statusUI,
          data.mobileApp.isRip,
          releaseDate,
        )
      })
      .catch((e) => {
        setSelectedStatus(currentStatus)
        setError(e)
        // eslint-disable-next-line no-console
        console.error(`🚨 ${e.message}`)
      })
      .finally(() => {
        setIsLoading(false)
        setTimeout(() => {
          setError(null)
          setIsSuccess(false)
        }, 2000)
      })
  }, [networkService, appId, platform, currentStatus, onAppStatusChange])

  return {isLoading, isSuccess, error, selectedStatus, submitStatusChange}
}
