import {Styles} from '../../types/Styles'

export const styles: Styles = {
  checkIcon: {
    color: 'green',
  },
  closeIcon: {
    color: 'red',
  },
  dropdownButton: {
    margin: 0,
    marginLeft: 4,
    borderRadius: 6,
  },
  downArrow: {
    fontSize: '75%',
  },
}
