import {Decoder, stringDecoder} from 'json-decoder'

export enum Platform {
    Android = 'Android',
    Ios = 'iOS'
}

type PlatformInfo = {
  supportedFileTypes: string[]
  storeName: string
  demoInfoLink: string
}

const platformInfo: Record<Platform, PlatformInfo> = {
  [Platform.Android]: {
    supportedFileTypes: ['.apk', '.aab'],
    storeName: 'Google',
    // eslint-disable-next-line max-len
    demoInfoLink: 'https://support.google.com/googleplay/android-developer/answer/9859455?hl=en#:~:text=App%20access',
  },
  [Platform.Ios]: {
    supportedFileTypes: ['.ipa'],
    storeName: 'Apple',
    demoInfoLink: 'https://developer.apple.com/app-store/review/guidelines/#app-completeness',
  },
}

export const getSupportedFileTypes =
(platform: Platform): string[] => platformInfo[platform]?.supportedFileTypes || []

export const getStoreName =
(platform: Platform): string => platformInfo[platform]?.storeName || ''

export const getDemoInfoLink =
(platform: Platform): string => platformInfo[platform]?.demoInfoLink || ''

const getPlatformFromString = (value: string): Platform => {
  switch (value) {
    case 'Android':
      return Platform.Android
    case 'iOS':
      return Platform.Ios
    default:
      // eslint-disable-next-line max-len
      throw new Error(`Could not decode ${value} to Platform. Allowed values are: ${Object.values(Platform)}`)
  }
}

export const platformDecoder: Decoder<Platform> = stringDecoder.map(getPlatformFromString)
