import React, {useState} from 'react'
import {Form, Radio, Input} from 'antd'
import BinaryUploader from './BinaryUploader'
import AppSubmissionFormHeader from './AppSubmissionFormHeader'
import {strings} from '../../localization/strings'
import {useWatch} from 'antd/es/form/Form'
import {Platform} from '../../types/Platform'
import {DistributionMethod, getAppIdentifierPrefix} from '../../types/DistributionMethod'
import {fieldName} from './AppSubmissionFieldNames'
import {PackagingType} from '../../types/PackagingType'

const PackagingSection: React.FC = () => {
  const form = Form.useFormInstance()

  const {Item} = Form
  const {submission: texts} = strings
  const {validation: validationMessage, placeholder} = texts

  const [isBinaryUploaded, setIsBinaryUploaded] = useState<boolean>(false)

  const platform = useWatch<Platform>(fieldName.platform, form)
  const distributionMethod = useWatch<DistributionMethod>(fieldName.appAvailability, form)
  const appIdentifier = useWatch<string>(fieldName.appIdentifier, form)
  const version = useWatch<string>(fieldName.version, form)
  const packagingType = useWatch<string>(fieldName.packagingType, form)

  const fieldsMissing = !platform || !appIdentifier || !version || !distributionMethod

  const onCompletion = (success: boolean, uploadUrl?: string) => {
    setIsBinaryUploaded(success)
    if (success && uploadUrl) {
      form.setFieldValue([fieldName.links, fieldName.artifactoryRepository], uploadUrl)
    }
  }

  const fieldsValidator = (_rule: any, value: any) => {
    if (value === PackagingType.Binary && fieldsMissing) {
      return Promise.reject(validationMessage.missingFieldForUpload)
    }
    return Promise.resolve()
  }

  const binaryUploadValidator = (_rule: any, value: any) => {
    if (value === PackagingType.Binary && !isBinaryUploaded && !fieldsMissing) {
      return Promise.reject(validationMessage.binaryMissing)
    }
    return Promise.resolve()
  }

  return (
    <>
      <AppSubmissionFormHeader title={texts.packaging} />

      <Item
        name={fieldName.packagingType}
        rules={[
          {required: true, message: validationMessage.packagingTypeRequired},
          {validator: fieldsValidator},
          {validator: binaryUploadValidator, validateTrigger: 'onBlur'},
        ]}>
        <Radio.Group>
          <Radio value={PackagingType.SourceCode}>{PackagingType.SourceCode}</Radio>
          <Radio value={PackagingType.Binary}>{PackagingType.Binary}</Radio>
        </Radio.Group>
      </Item>

      {packagingType === PackagingType.SourceCode && (
        <Item
          name={[fieldName.links, fieldName.sourceCodeRepository]}
          rules={[
            {required: true, message: validationMessage.sourceCodeUrlRequired},
            {type: 'url', message: validationMessage.invalidUrl},
          ]}
        >
          <Input placeholder={placeholder.sourceCodeUrl} />
        </Item>
      )}

      {packagingType === PackagingType.Binary && !fieldsMissing && (
        <Item
          name={[fieldName.links, fieldName.artifactoryRepository]}
        >
          <BinaryUploader
            platform={platform}
            appId={getAppIdentifierPrefix(distributionMethod) + appIdentifier}
            appVersion={version}
            onCompletion={onCompletion}
          />
        </Item>
      )}
    </>
  )
}

export default PackagingSection
