import React, {useState} from 'react'
import {Button, Space, Typography, Flex} from 'antd'
import {strings} from '../../localization/strings'
import {AppLink} from '../../types/AppLink'
import AppInstallationLinkModal from './AppInstallationLinkModal'

export type AppLinksProps = {
    appLinks: AppLink[]
}
const {Title} = Typography

const AppLinksSection: React.FC<AppLinksProps> = ({appLinks}) => {
  const appLinksText = strings.appDetail.appLinks
  const [appInstallationLink, setAppInstallationLink] = useState<AppLink | undefined>(undefined)

  const handleOnAppIntallationLinkButtonClick = (
    appLink: AppLink,
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    event.stopPropagation()
    setAppInstallationLink(appLink)
  }

  const renderAppInstallationLinkButton = (appLink: AppLink) => (
    <Button
      type="primary"
      onClick={(event) => handleOnAppIntallationLinkButtonClick(appLink, event)}
      style={{borderRadius: 5}}
    >
      {appLink.title}
    </Button>
  )

  const renderDefaultAppLinkButton = (appLink: AppLink) => (
    <Button
      type="primary"
      href={appLink.link}
      target="_blank"
      style={{borderRadius: 5}}
    >
      {appLink.title}
    </Button>
  )

  const handleOnCloseAppInstallationLinkModal = () => {
    setAppInstallationLink(undefined)
  }

  return (
    <div>
      <Space direction="vertical">
        <Title level={4}> {appLinksText.title} </Title>
        <Flex wrap="wrap" gap={'small'}>
          {
            appLinks.map((appLink) => (
              <Space key={appLink.title}>
                {
                  appLink.isAppInstallationLink
                    ? renderAppInstallationLinkButton(appLink)
                    : renderDefaultAppLinkButton(appLink)
                }
              </Space>
            ))
          }
        </Flex>
      </Space>
      {
        appInstallationLink &&
        <AppInstallationLinkModal
          appLink={appInstallationLink}
          onCloseAppInstallationLinkModal={handleOnCloseAppInstallationLinkModal} />
      }
    </div>)
}

export default AppLinksSection
