import {Styles} from '../../types/Styles'

export const styles: Styles = {
  formContainer: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    paddingRight: '50px',
    paddingLeft: '50px',
    backgroundColor: 'white',
    borderRadius: '8px',
  },
  formSection: {
    marginBottom: '20px',
  },
  submitButtonContainer: {
    textAlign: 'right',
    marginTop: '50px',
  },
  warningExtra: {
    color: 'orange',
    marginTop: '5px',
    display: 'block',
    fontSize: '12px',
  },
  infoExtra: {
    marginTop: '5px',
    display: 'block',
    fontSize: '12px',
  },
  iconPrefix: {
    marginRight: '5px',
  },
  demoContainer: {
    marginBottom: '30px',
    backgroundColor: '#f0f2f5',
    paddingTop: '1px',
    paddingLeft: '20px',
    paddingBottom: '10px',
    paddingRight: '20px',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  },
  fieldSummary: {
    marginBottom: '15px',
    display: 'block',
    fontSize: '13px',
  },
  compactInput: {
    width: '50%',
  },
  hidden: {
    display: 'none',
  },
  messageContainer: {
    backgroundColor: '#f5f5f5',
    borderRadius: '16px',
    padding: '10px 15px',
    margin: '10px 0px',
    textAlign: 'center',
  },
}
