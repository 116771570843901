import React, {useState, useEffect} from 'react'
import {Upload, Progress, Button} from 'antd'
import {InboxOutlined, UploadOutlined, DeleteOutlined} from '@ant-design/icons'
import {UploadFile} from 'antd/lib/upload/interface'
import './FileDragger.css'
import {UploadStatus} from '../../../types/UploadStatus'
import {strings} from '../../../localization/strings'

const {Dragger} = Upload

export type FileDraggerProps = {
  onFileUpload: (file: File, onProgress: (percent: number) => void) => void
  onFileRemove: () => void
  uploadStatus: UploadStatus
  acceptedFileExtensions: string[]
}

const FileDragger: React.FC<FileDraggerProps> = ({
  onFileUpload,
  onFileRemove,
  uploadStatus,
  acceptedFileExtensions,
}) => {
  const [file, setFile] = useState<UploadFile<any> | null>(null)
  const [progress, setProgress] = useState<number>(0)
  const supportedFileTypes = acceptedFileExtensions.join(', ')

  useEffect(() => {
    if (uploadStatus !== UploadStatus.Uploading) {
      setProgress(0)
    }
  }, [uploadStatus])

  const handleProgressUpdate = (percent: number) => {
    setProgress(percent)
  }

  const handleUpload = () => {
    if (!file) return
    onFileUpload(file.originFileObj as File, handleProgressUpdate)
  }

  const handleRemoveFile = () => {
    setFile(null)
    setProgress(0)
    onFileRemove()
  }

  const renderProgress = () => {
    if (uploadStatus === UploadStatus.Uploading) {
      return (
        <div className="progress">
          <Progress percent={progress} status="active" />
        </div>
      )
    }
    return null
  }

  const renderFile = () => {
    if (file) {
      return (
        <div className="selected-file">
          <h4>{strings.fileUpload.selectedFile}</h4>
          <div className="file-info">
            <p className="file-name">{file.name}</p>
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={handleRemoveFile}
              className="remove-button"
            >
              {strings.buttonTitle.remove}
            </Button>
          </div>
        </div>
      )
    }
    return (
      <Dragger
        multiple={false}
        showUploadList={false}
        fileList={file ? [file] : []}
        customRequest={() => false}
        onChange={(info) => {
          setFile(info.file)
        }}
        accept={supportedFileTypes}
      >
        <div className="drag-container">
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{strings.fileUpload.draggerText}</p>
          <p className="ant-upload-hint">{strings.fileUpload.draggerHint}{supportedFileTypes}</p>
        </div>
      </Dragger>
    )
  }

  return (
    <div className="file-dragger">
      {renderFile()}
      {renderProgress()}
      {file && uploadStatus !== UploadStatus.Uploading && uploadStatus !== UploadStatus.Success && (
        <div className="upload-button-container">
          <Button type="primary" icon={<UploadOutlined />} onClick={handleUpload}>
            {strings.buttonTitle.upload}
          </Button>
        </div>
      )}
    </div>
  )
}

export default FileDragger
