import {MaspError, MaspErrorType} from '../errors/MaspError'
import {Environment} from '../types/Environment'

export class ConfigHelper {
  private readonly environment?: string

  constructor(environment?: string) {
    this.environment = environment
  }

  getEnvironment = (): Environment => {
    switch (this.environment) {
      case Environment.Local:
        return Environment.Local
      case Environment.Dev:
        return Environment.Dev
      case Environment.Test:
        return Environment.Test
      case Environment.Prod:
        return Environment.Prod
      default: {
        const environmentError = new MaspError(MaspErrorType.Environment)
        throw environmentError
      }
    }
  }

  getBackendBaseUrl = (): string => {
    switch (this.environment) {
      case Environment.Local:
        return 'http://localhost:7071/api'
      case Environment.Dev:
        return 'https://masp-backend-dev.azurewebsites.net/api'
      case Environment.Test:
        return 'https://masp-backend-test.azurewebsites.net/api'
      case Environment.Prod:
        return 'https://masp-backend.azurewebsites.net/api'
      default: {
        const environmentError = new MaspError(MaspErrorType.Environment)
        throw environmentError
      }
    }
  }

  getMsalClientId = (): string => {
    switch (this.environment) {
      case Environment.Dev:
        return 'f16b818f-13ed-4979-b8ef-4521b92675e9'
      case Environment.Test:
        return 'e63b320b-f0c3-4d1b-84cd-b3fd56f9d24d'
      case Environment.Prod:
        return 'be54b329-b15f-427d-a99a-9e5006125168'
      default:
        return ''
    }
  }

  getMsalRedirectUri = (): string => {
    const nodeEnv = process.env.NODE_ENV

    switch (this.environment) {
      case Environment.Dev:
        return nodeEnv === 'production'
          ? 'https://masp-dev.azurewebsites.net'
          : 'http://localhost:3000'
      case Environment.Test:
        return 'https://masp-test.azurewebsites.net'
      case Environment.Prod:
        // TODO: Temporary fix for prod redirect URL - can be removed once masp.merck.com will work properly
        return window.location.hostname === 'masp.merck.com'
          ? 'https://masp.merck.com'
          : 'https://masp.azurewebsites.net'
      default:
        return ''
    }
  }

  getMsalScopes = (): string[] => {
    switch (this.environment) {
      case Environment.Dev:
      case Environment.Test:
      case Environment.Prod:
        return [`api://${this.getMsalClientId()}/user_impersonation`]
      default:
        return []
    }
  }

  isMsalActive = (): boolean => {
    switch (this.environment) {
      case Environment.Dev:
      case Environment.Test:
      case Environment.Prod:
        return true
      default:
        return false
    }
  }

  getAppVersion = (appVersion?: string): string => {
    if (!appVersion) {
      const appVersionError = new MaspError(MaspErrorType.AppVersion)
      throw appVersionError
    }

    return appVersion
  }

  // eslint-disable-next-line max-len
  getArtifactoryBaseUrl = (): string => `https://api.merck.com/artifactory/artifactory/generic-masp-dev-local/BUILDS/${this.environment}`
}
