import {Decoder, stringDecoder} from 'json-decoder'
import {Platform} from './Platform'
import {DistributionMethod} from './DistributionMethod'
import {Division} from './Division'

export enum Publisher {
  IntervetInternational = 'Intervet International B.V.',
  IntervetInternationalEnterprise = 'Intervet International b.v. (enterprise)',
  IntervetInternationalGooglePlay = 'Intervet International b.v. (Google Play)',
  MerckCoInc = 'Merck & Co Inc',
  MerckCoIncEnterprise = 'MERCK & CO., INC. (enterprise)',
  MerckCoIncGooglePlay = 'Merck & Co Inc (Google Play)',
  MerckSharpDohmeLLC = 'Merck Sharp & Dohme LLC',
  MerckSharpDohmeLLCGooglePlay = 'Merck Sharp & Dohme LLC (Google Play)',
  MsdCzechRepublic = 'MSD Czech Republic s.r.o.'
}

const getPublisherFromString = (value: string): Publisher => {
  switch (value) {
    case 'Intervet International B.V.':
      return Publisher.IntervetInternational
    case 'Intervet International b.v. (enterprise)':
      return Publisher.IntervetInternationalEnterprise
    case 'Intervet International b.v. (Google Play)':
      return Publisher.IntervetInternationalGooglePlay
    case 'Merck & Co Inc':
      return Publisher.MerckCoInc
    case 'MERCK & CO., INC. (enterprise)':
      return Publisher.MerckCoIncEnterprise
    case 'Merck & Co Inc (Google Play)':
      return Publisher.MerckCoIncGooglePlay
    case 'Merck Sharp & Dohme LLC':
      return Publisher.MerckSharpDohmeLLC
    case 'Merck Sharp & Dohme LLC (Google Play)':
      return Publisher.MerckSharpDohmeLLCGooglePlay
    case 'MSD Czech Republic s.r.o.':
      return Publisher.MsdCzechRepublic
    default:
      // eslint-disable-next-line max-len
      throw new Error(`Could not decode ${value} to Publisher.  Allowed values are: ${Object.values(Publisher)}`)
  }
}

export const publisherDecoder: Decoder<Publisher> = stringDecoder.map(getPublisherFromString)

const getIosPublisher = (
  distributionMethod: DistributionMethod,
  division: Division,
): Publisher => {
  if (distributionMethod === DistributionMethod.ABM) {
    return Publisher.MsdCzechRepublic
  }

  return division === Division.AH ? Publisher.IntervetInternational : Publisher.MerckSharpDohmeLLC
}

export const getPublisher = (
  distributionMethod: DistributionMethod,
  division: Division,
  platform: Platform,
): Publisher => {
  switch (platform) {
    case Platform.Ios:
      return getIosPublisher(distributionMethod, division)
    case Platform.Android:
      return division === Division.AH
        ? Publisher.IntervetInternationalGooglePlay
        : Publisher.MerckSharpDohmeLLCGooglePlay
    default:
      throw new Error('Not supported platform!')
  }
}
