import React, {useCallback} from 'react'
import {MobileAppStatusUI} from '../../types/MobileAppStatusUI'
import {PresetColorType} from 'antd/es/_util/colors' // eslint-disable-line import/named
import {styles} from './StatusLabel.styles'
import {config} from '../../config/config'
import LinkTag from '../shared/LinkTag/LinkTag'

export type StatusLabelProps = {
  status?: MobileAppStatusUI
}

export const StatusLabel: React.FC<StatusLabelProps> = ({status}) => {
  const getColor = useCallback((): PresetColorType => {
    switch (status) {
      case MobileAppStatusUI.RequestInReview:
      case MobileAppStatusUI.CodeEvaluation:
      case MobileAppStatusUI.AppStoreReview:
        return 'blue'
      case MobileAppStatusUI.PendingOwnerAction:
      case MobileAppStatusUI.UserTesting:
      case MobileAppStatusUI.DivisionalApproval:
      case MobileAppStatusUI.AppInfoNeeded:
        return 'volcano'
      default:
        return 'red'
    }
  }, [status])

  return status &&
  <LinkTag
    url={config.releaseProcessOverviewUrl}
    title={status}
    tagColor={getColor()}
    tagStyle={styles.tag}
  />
}

StatusLabel.defaultProps = {
  status: undefined,
}
