import {Decoder, stringDecoder} from 'json-decoder'

export enum MobileAppStatusUI {
  RequestInReview = 'Request in review',
  CodeEvaluation = 'Code evaluation',
  PendingOwnerAction = 'Pending owner action',
  UserTesting = 'User testing',
  DivisionalApproval = 'Divisional approval',
  AppInfoNeeded = 'App info needed',
  AppStoreReview = 'App store review'
}

const getMobileAppStatusUIFromString = (value: string): MobileAppStatusUI => {
  switch (value) {
    case 'Request in review':
      return MobileAppStatusUI.RequestInReview
    case 'Code evaluation':
      return MobileAppStatusUI.CodeEvaluation
    case 'Pending owner action':
      return MobileAppStatusUI.PendingOwnerAction
    case 'User testing':
      return MobileAppStatusUI.UserTesting
    case 'Divisional approval':
      return MobileAppStatusUI.DivisionalApproval
    case 'App info needed':
      return MobileAppStatusUI.AppInfoNeeded
    case 'App store review':
      return MobileAppStatusUI.AppStoreReview
    default:
      throw new Error(
        `Could not decode ${value} to MobileAppStatusUI. 
        Allowed values are: ${Object.values(MobileAppStatusUI)}`,
      )
  }
}

export const mobileAppStatusUIDecoder: Decoder<MobileAppStatusUI> = stringDecoder.map(
  getMobileAppStatusUIFromString,
)
