import React from 'react'
import {ConfigProvider, Divider} from 'antd'

export type AppSubmissionFormHeaderProps = {
  title: string
}

const AppSubmissionFormHeader: React.FC<AppSubmissionFormHeaderProps> = ({title}) => (
  <ConfigProvider
    theme={{
      token: {
        colorSplit: 'black',
      },
    }}
  >
    <Divider orientation="left" plain><h3>{title}</h3></Divider>
  </ConfigProvider>

)

export default AppSubmissionFormHeader
