import {objectDecoder, stringDecoder} from 'json-decoder'

export type Assessments = {
  privacyId: string
  riskId: string
}

export const assessmentsDecoder = objectDecoder<Assessments>({
  privacyId: stringDecoder,
  riskId: stringDecoder,
})
