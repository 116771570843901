import {Card, Image, Button, Flex, Typography, Space} from 'antd'
import {strings} from '../../localization/strings'
import React from 'react'
import {Platform} from '../../types/Platform'
import {MobileAppListItem} from '../../types/MobileAppListItem'
import {StatusLabel} from '../AppStatus/StatusLabel'
import StatusSettings, {AppStatusChangeCallbackParams} from '../AppStatus/StatusSettings'
import {styles} from './AppCard.styles'
import {useUserInfo} from '../../hooks/useUserInfo'
import {AndroidFilled, AppleFilled, LinkOutlined} from '@ant-design/icons'
import LinkTag from '../shared/LinkTag/LinkTag'

const DEFAULT_ICON = '/images/merck_teal_default_icon.png'
const APP_ICON_SIZE: number = 50
const {Text} = Typography

export type AppCardProps = {
    mobileApp: MobileAppListItem
    isRip: boolean
    onAppCardClick: (
      id: string,
      platform: Platform,
      event: React.MouseEvent<HTMLElement, MouseEvent>) => void
    onUpdateClick: (id: string, platform: Platform) => void
    // eslint-disable-next-line react/require-default-props
    onAppStatusChange?: AppStatusChangeCallbackParams
}

const AppCard: React.FC<AppCardProps> = ({
  mobileApp,
  isRip,
  onUpdateClick,
  onAppCardClick,
  onAppStatusChange,
}) => {
  const {id, division, icon, name, platform, version} = mobileApp
  const {isAdmin} = useUserInfo()

  const handleOnUpdateClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()
    onUpdateClick(id, platform)
  }

  const renderButton = () => (
    <Button
      type="primary"
      onClick={handleOnUpdateClick}
      style={styles.releaseButton}
    >
      {strings.buttonTitle.newRelease}
    </Button>
  )

  const renderButtonsForRip = () => (
    <Flex align="center">
      {mobileApp.jiraIssue.url &&
      <LinkTag
        url={mobileApp.jiraIssue.url}
        title={strings.buttonTitle.jira}
        icon={<LinkOutlined />}
        tagColor="geekblue"
        tagStyle={styles.jiraTag} />
      }
      <StatusLabel status={mobileApp.statusUI} />
      {isAdmin && onAppStatusChange &&
        <StatusSettings
          appId={mobileApp.id}
          platform={mobileApp.platform}
          currentStatus={mobileApp.status.name}
          onAppStatusChange={onAppStatusChange} />
      }
    </Flex>
  )

  const getPlatformIcon = () => {
    if (mobileApp.platform === Platform.Ios) {
      return <AppleFilled style={styles.platformIcon} />
    }
    return <AndroidFilled style={styles.platformIcon} />
  }

  return (
    <Card
      bordered
      hoverable
      onClick={(event) => onAppCardClick(id, platform, event)}
      style={styles.card}
    >
      <Flex vertical>
        <Flex gap="small">
          <Image
            src={icon}
            fallback={DEFAULT_ICON}
            width={APP_ICON_SIZE}
            height={APP_ICON_SIZE}
            preview={false}
            style={styles.appIcon}
          />
          <Flex vertical style={styles.appInfo}>
            <Text strong style={styles.appName}>{name}</Text>
            <Space size={5}>
              <Text style={styles.appDetail}>v{version} - {division}</Text>
              {isRip && getPlatformIcon()}
            </Space>
          </Flex>
        </Flex>
        <div style={styles.buttonContainer}>
          {isRip
            ? renderButtonsForRip()
            : renderButton()
          }
        </div>
      </Flex>
    </Card>
  )
}

export default AppCard
