import {Decoder, stringDecoder} from 'json-decoder'

export enum Division {
  AH = 'AH',
  HH = 'HH',
  MMD = 'MMD',
  MRL = 'MRL',
  Other = 'Other'
}

const getDivisionFromString = (value: string): Division => {
  switch (value) {
    case 'AH':
      return Division.AH
    case 'HH':
      return Division.HH
    case 'MMD':
      return Division.MMD
    case 'MRL':
      return Division.MRL
    case 'Other':
      return Division.Other
    default:
      // eslint-disable-next-line max-len
      throw new Error(`Could not decode ${value} to Division. Allowed values are: ${Object.values(Division)}`)
  }
}

export const divisionDecoder: Decoder<Division> = stringDecoder.map(getDivisionFromString)
