import {createRoot} from 'react-dom/client'
import * as Sentry from '@sentry/react'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {config} from './config/config'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'

dayjs.extend(weekday)
dayjs.extend(localeData)

Sentry.init({
  dsn: 'https://faf4c09258f06b2e260719a0e5bf8138@o135574.ingest.sentry.io/4506001594253312',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const container = document.getElementById('root')
if (!container) {
  throw Error('HTML element #root must exist for this app to work.')
}

createRoot(container).render(<App msal={config.msal} />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
