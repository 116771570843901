export enum MaspErrorType {
    AppDetailMissingParams = 'Platform or app ID was not set to fetch app detail.',
    AppVersion = 'App version was not set correctly (REACT_APP_VERSION must be set!)',
    CsvParsing = 'Unable to parse Csv text.',
    Environment = 'Environment was not set correctly.',
    Network = 'The network request failed',
    MsalAuthentication = 'Msal Authentication.',
    MsalAccount = 'MSAL: No user is logged in',
    MsalToken = 'MSAL: Unable to acquire access token',
    JsonParsing = 'Unable to parse JSON object.',
    JsonDecoding = 'Unable to decode JSON object.',
    UploadFailed = 'Unable to upload file.',
}

export class MaspError extends Error {
  constructor(type: MaspErrorType, detail?: string) {
    super(type)
    detail && (this.message += `: ${detail}`)
  }
}
