import {Styles} from '../../types/Styles'

export const styles: Styles = {
  testContainer: {
    borderRadius: 8,
    margin: 10,
    border: '1px solid grey',
  },
  demoContainer: {
    padding: 10,
    borderRight: '1px solid grey',
  },
  appTestersContainer: {
    padding: 10,
  },
  demoInstructions: {
    display: 'block',
    marginBottom: 5,
  },
}
