import {objectDecoder, stringDecoder} from 'json-decoder'

export type SubmissionResponse = {
    message: string,
    jiraTicketLink: string,
}

export const submissionResponseDecoder = objectDecoder<SubmissionResponse>({
  message: stringDecoder,
  jiraTicketLink: stringDecoder,
})
