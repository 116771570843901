import React, {useEffect, useRef, useState} from 'react'
import './Home.css'
import {Flex, Input, Space} from 'antd'
import AppsSection from '../components/AppsSection/AppsSection'
import {NetworkService} from '../services/NetworkService'
import {config} from '../config/config'
import {Spinner} from '../components/Spinner'
import {strings} from '../localization/strings'
import {SearchOutlined} from '@ant-design/icons'
import {MobileAppListItem} from '../types/MobileAppListItem'
import {Section} from '../types/Section'
import {MobileAppStatusName} from '../types/MobileAppStatusName'
import {AppStatusChangeCallbackParams} from '../components/AppStatus/StatusSettings'
import {MobileAppStatusUI} from '../types/MobileAppStatusUI'
import {Platform} from '../types/Platform'
import {useUserInfo} from '../hooks/useUserInfo'
import MaspDataDownloader from '../components/DataExport/MaspDataDownloader'

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [rips, setRips] = useState<MobileAppListItem[]>([])
  const [filteredRips, setFilteredRips] = useState<MobileAppListItem[]>([])
  const [iosApps, setIosApps] = useState<MobileAppListItem[]>([])
  const [filteredIosApps, setFilteredIosApps] = useState<MobileAppListItem[]>([])
  const [androidApps, setAndroidApps] = useState<MobileAppListItem[]>([])
  const [filteredAndroidApps, setFilteredAndroidApps] = useState<MobileAppListItem[]>([])
  const {current: networkService} = useRef(new NetworkService(config.backendBaseUrl, config.msal))
  const [searchQuery, setSearchQuery] = useState('')
  const {isAdmin} = useUserInfo()

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase())
  }

  const handleAppStatusChange: AppStatusChangeCallbackParams =
  (id: string, status: MobileAppStatusName, statusUI?: MobileAppStatusUI) => {
    const index = rips.findIndex((item) => item.id === id)
    if (index === -1) {
      return
    }
    const updatedMobileApp = {...rips[index], status: {name: status}, statusUI}
    if (status === MobileAppStatusName.Released || status === MobileAppStatusName.Cancelled) {
      const updatedRips = rips.filter((item) => item.id !== updatedMobileApp.id)
      setRips(updatedRips)
      if (status === MobileAppStatusName.Released) {
        if (updatedMobileApp.platform === Platform.Ios) {
          setIosApps([...iosApps, updatedMobileApp])
        } else {
          setAndroidApps([...androidApps, updatedMobileApp])
        }
      }
      return
    }
    const updatedRips = [...rips]
    updatedRips[index] = updatedMobileApp
    setRips(updatedRips)
  }

  useEffect(() => {
    const fetchMobileApps = async () => {
      try {
        const [
          fetchedRips,
          fetchedIosApps,
          fetchedAndroidApps,
        ] = await Promise.all([
          networkService.getMobileApps('rip'),
          networkService.getMobileApps('ios'),
          networkService.getMobileApps('android'),
        ])
        setRips(fetchedRips)
        setFilteredRips(fetchedRips)
        setIosApps(fetchedIosApps)
        setFilteredIosApps(fetchedIosApps)
        setAndroidApps(fetchedAndroidApps)
        setFilteredAndroidApps(fetchedAndroidApps)
        setIsLoading(false)
      } catch (error) {
        // TO-DO:- Error Handling to be done
        // eslint-disable-next-line no-console
        console.log(`${error}`)
        setIsLoading(false)
      }
    }
    fetchMobileApps()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setFilteredRips(rips.filter((app) =>
      app.name.toLowerCase().includes(searchQuery),
    ))
    setFilteredIosApps(iosApps.filter((app) =>
      app.name.toLowerCase().includes(searchQuery),
    ))
    setFilteredAndroidApps(androidApps.filter((app) =>
      app.name.toLowerCase().includes(searchQuery),
    ))
  }, [androidApps, iosApps, rips, searchQuery])

  return (
    <>
      { isLoading ? (<Spinner tip={strings.loadingMessage.fetchingData} />)
        : (
          <Space direction="vertical" size="large" className="layout">
            <Flex align="center">
              <Input
                addonBefore={<SearchOutlined />}
                allowClear
                size="large"
                onChange={handleSearch}
                placeholder={strings.search.title}
                style={{
                  margin: '20px 0px 0px 20px',
                  paddingRight: '40px',
                }}
              />
              {isAdmin && <MaspDataDownloader />}
            </Flex>

            {rips.length > 0 && (
              <section className="appContentCard">
                <AppsSection
                  section={Section.Rip}
                  apps={filteredRips}
                  searchQuery={searchQuery}
                  onAppStatusChange={handleAppStatusChange}
                />
              </section>
            )}

            <section className="appContentCard">
              <AppsSection
                section={Section.Ios}
                apps={filteredIosApps}
                searchQuery={searchQuery}
              />
            </section>
            <section className="appContentCard">
              <AppsSection
                section={Section.Android}
                apps={filteredAndroidApps}
                searchQuery={searchQuery}
              />
            </section>
          </Space>
        )
      }
    </>
  )
}

export default App
